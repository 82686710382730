<style lang="scss" scoped>
.el-popover {
    max-width: unset !important;
}
.face-list {
    cursor: pointer;
    .face {
        font-size: 20px;
        display: inline-block;
        width: 25px;
        &:hover {
            opacity: .7;
        }
    }
}
</style>

<template>
    <el-popover placement="top-start" width="300" trigger="hover">
        <i slot="reference" class="icon icon-face"></i>
        <div class="face-list">
            <span class="face" v-for="item in $variable.faceList" :key="item" @click="addFace(item)">{{$help.strToFace(item)}}</span>
        </div>
    </el-popover>
</template>
<script>
export default {
    methods: {
        addFace(item) {
            this.$emit('input', this.$help.strToFace(item))
        }
    }
}
</script>