<template>
    <div class="feed-back">
        <feed-dialog :titleImg="titleImg" :defaultWidth="'700px'" :dialog-show="showBack" class="back-dialog">
            <div class="title" slot="title" >
                <i class="el-icon-s-order"></i>
                <span>信息反馈</span>
            </div>
            <div slot="content" class="content">
                <div class="image" v-show="showFeed.showEmpty">
                    <el-image class="feedEmpty" :src="feedEmpty" fit="fill"></el-image>
                    <div>
                        您还未提交过信息反馈~
                    </div>
                </div>
                <div class="areaText" v-show="showFeed.showInput">
                    <div class="title" @click="callBack"><i class="el-icon-arrow-left"></i><span>填写反馈</span></div>
                    <div class="add-item">
                        <div class="add-title">具体描述：</div>
                        <div class="add-content">
                            <el-input type="textarea" :rows="4" @paste.native="pasteContent"
                                      placeholder="请具体描述您的问题，可以直接粘贴图片" v-model="textarea"></el-input>
                        </div>
                    </div>
                    <div class="add-item">
                        <div class="add-title">上传图片：</div>
                        <div class="add-content" v-if="showFeed.showInput">
                            <el-upload ref="upload" action="//upload-z2.qiniup.com" list-type="picture-card" :data="postData" :before-upload="beforeUpload"
                                       :on-preview="handleImgPreview" :file-list="pictures" :on-remove="removeImg" :on-success="successImg">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                    </div>
                </div>
                <div class="feedList" v-show="showFeed.showList">
                    <div class="title">
                        <div class="info">
                            您共反馈了 <span class="color">{{params.total}}</span> 条信息
                        </div>
                        <div class="img" @click="goFeedBack">
                            <el-image class="addFeed" :src="addFeed" fit="fill"></el-image>
                            <span>添加反馈</span>
                        </div>
                    </div>
                    <div v-if="listLoaded" class="list"  v-infinite-scroll="onLoadList" :infinite-scroll-disabled="params.isLast">
                        <div v-for="(item, i) in feedList" :key="i" class="item" :class="{'gray': item.status === 2}">
                            <div class="left">
                                {{item.createdAt}}
                            </div>
                            <div class="right" >
                                <span>{{statusList[item.status]}}</span>
                                <span>|</span>
                                <span class="clickable" @click="goDetail(item)">详情</span>
                            </div>
                        </div>
                        <div class="loading" v-if="loading">加载中...</div>
                        <div class="nomore" v-if="noMore">没有更多了</div>
                    </div>
                    <div style="text-align: right;color:#999;margin-top: 10px;">联系电话：{{$variable.tel}}</div>
                </div>
                <div class="details" v-show="showFeed.showDetail">
                    <div class="title" @click="callBack"><i class="el-icon-arrow-left"></i><span>反馈详情</span></div>
                    <div class="details-content">
                        <div class="date">{{detail.createdAt}}</div>
                        <p>{{detail.feedbackContent}}</p>
                        <el-upload class="readonly" v-if="pictures.length > 0" action="//upload-z2.qiniup.com" list-type="picture-card"
                                   disabled :file-list="pictures" :on-preview="handleImgPreview"></el-upload>
                    </div>
                    <div class="details-response" v-show="detail.replyContent">
                        <div class="title">
                            官方回复:
                        </div>
                        <div class="res-content">
                            <p>{{detail.replyContent}}</p>
                            <div class="date">{{detail.replyTime}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button type="primary" v-show="showFeed.showEmpty" @click="goFeedBack">去反馈</el-button>
                <el-button type="primary" v-show="showFeed.showInput" :loading="submitLoading" @click="submitFeed">提交</el-button>
            </div>
        </feed-dialog>

        <ts-dialog :show.sync="preview.show" title="预览" hide-footer width="800px">
            <img :src="preview.url" alt="">
        </ts-dialog>

        <el-dialog :visible.sync="imgPopup.show" title="确认新增吗？" width="500px" destroy-on-close append-to-body>
            <div style="text-align: center">
                <el-image :src="imgPopup.url" fit="container"></el-image>
            </div>
            <div style="margin-top: 15px; text-align: right">
                <el-button type="primary" @click="confirmImg" :loading="imgPopup.loading">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import qiniu from '@/util/qiniu'
export default {
    props: {
        showBack:{
            type: Object,
            default: () =>{
                return {
                    show: false,
                }
            }
        }
    },
    data() {
        return {
            action: qiniu.upload,
            postData: {
                token: '',
                key: '',
            },

            preview: {
                show: false,
                url: '',
            },

            listLoaded: true,
            feedEmpty: require('@/assets/img/feed-empty.png'),
            titleImg: require('@/assets/img/home-dialog.png'),
            addFeed: require('@/assets/img/add-feed.png'),
            showFeed: {
                showEmpty: false,
                showInput: false,
                showList: false,
                showDetail: false,
            },
            loading: false,
            params: {
                page: 0,
                size: 10,
                isLast: false,
                total: 0,
            },
            detail: {},

            // 新增时需要的参数
            textarea: '',
            pictures: [],


            feedList: [],
            submitLoading: false,
            statusList: ['', '待反馈', '已反馈' ],

            imgPopup: {
                flag: true,  // 为了强制刷新upload组件
                show: false,
                url: '',
                loading: false,
            },
        }
    },
    created() {
        this.initShowFeed('showList')
    },
    computed: {
        disabled () {
            return this.loading || this.noMore
        },
        noMore () {
            return this.feedList.length >= this.total
        }
    },
    methods: {
        init() {
            this.loading = true
            this.params.page = 0
            this.params.pageSize = 10
            this.feedList = []
            this.onLoadList()
        },
        getList() {
            this.loading = true
            this.$api.post('sys/sysUserFeedback/querySysUserFeedbackPage', {
                page: {pageNo: this.params.page, pageSize: this.params.size},
                sort: {createdAt: 'desc'},
            })
                .then(res => {
                    if (res.code === this.$variable.SUCCESS_CODE) {
                        this.params.total = res.data.totalCount
                        if (this.params.page >= res.data.totalPage) {
                            this.params.isLast = true
                        }
                        if (this.params.page === 1 && res.data.totalCount === 0) {
                            this.initShowFeed('showEmpty')
                        }
                        this.feedList = this.feedList.concat(res.data.records)
                    }else{
                        this.params.isLast = true
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        callBack() {
            if(this.feedList.length === 0){
                this.initShowFeed('showEmpty')
            }else{
                this.initShowFeed('showList')
            }
        },
        initShowFeed(show = '') {
            for(let i in this.showFeed){
                this.$set(this.showFeed, i, false)
            }
            if(show !== ''){
                this.$set(this.showFeed, show, true)
            }
        },
        goFeedBack() {
            this.textarea = ''
            this.pictures = []
            this.initShowFeed('showInput')
        },
        goDetail(detail) {
            this.detail = detail
            if (detail.pictures) {
                this.pictures = detail.pictures.split('*##*').map(item => {
                    return {
                        url: item,
                        status: 'success',
                    }
                })
            } else {
                this.pictures = []
            }
            this.initShowFeed('showDetail')
        },
        onLoadList() {
            this.params.page++
            this.getList()
        },
        submitFeed() {
            this.submitLoading = true
            this.$api.post('sys/sysUserFeedback/insertSysUserFeedback', {
                feedbackContent: this.textarea,
                pictures: this.pictures.map(item => item.url).join('*##*'),
            })
                .then(() => {
                    this.params.page = 0
                    this.params.total = 0
                    this.params.isLast = false
                    this.listLoaded = false
                    this.feedList = []
                    this.initShowFeed('showList')
                    this.$store.commit('success', '提交成功')
                    this.$nextTick(() => {
                        this.listLoaded = true
                    })
                })
                .finally(() => {
                    this.submitLoading = false
                })
        },

        beforeUpload(file) {
            return qiniu.getToken().then(res => {
                if(res.success){
                    this.postData.token = res.data
                    const name = file.name
                    const splitStart = name.lastIndexOf('.')
                    this.postData.key = `${name.substr(0, splitStart)}-${new Date().Format('yyyyMMddhhmmss')}${name.substr(splitStart)}`
                }
            })
        },
        successImg(res) {
            if (res.success) {
                this.pictures.push({
                    url: res.data.url,
                    status: 'success',
                })
            }
        },
        removeImg(file) {
            const index = this.pictures.findIndex(item => item.url === file.response.data.url)
            this.pictures.splice(index, 1)
        },
        handleImgPreview(e) {
            this.preview.url = e.url
            this.preview.show = true
        },


        // 粘贴图片
        pasteContent(e) {
            const pasteData = e.clipboardData || window.e.originalEvent.clipboardData

            if (pasteData && pasteData.getData('Text')) {
                // 如果复制的是文字，则不做处理
            } else {
                const items = pasteData.items
                if (items.length > 0 && items[0].kind === 'file') {
                    const item = items[0]
                    if ((/^image\/[jpeg|png|gif|jpg]/.test(item.type))) {
                        const file = e.clipboardData.files[0];
                        const reader = new FileReader()
                        reader.readAsDataURL(file)
                        // 监听读取完成
                        reader.onload = () => {
                            this.uploadBase64(reader.result)
                        }
                    }
                }
            }
        },
        // 粘贴的图片进行上传
        uploadBase64(data) {
            this.imgPopup.show = true
            this.imgPopup.url = data
            this.imgPopup.loading = false
        },
        confirmImg() {
            this.imgPopup.loading = true
            qiniu.uploadByBase64(this.imgPopup.url)
                .then(res => {
                    this.imgPopup.show = false
                    this.pictures.push({
                        url: res.url,
                        status: 'success',
                    })
                })
                .catch(() => {
                    this.sendLoading = false
                })
        },
    },
    mounted() {
        this.onLoadList()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .feed-back{
        .back-dialog{
            .content{
                padding-bottom: 10px;
                .areaText{
                    .title{
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        padding-bottom: 10px;
                        display: flex;
                        align-items: center;
                        i{
                            padding-right: 5px;
                            font-size: 22px;
                        }
                    }
                    .el-textarea{
                        //height: 220px;
                        font-size: 14px;
                        box-sizing: border-box;
                    }   
                }
                .feedList{
                    .title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .info{
                            font-weight: bold;
                            color: #333333;
                        }
                        .color{
                            color: #F22A2A;
                        }
                        .img{
                            display: flex;
                            align-items: center;
                            span{
                                color: $main;
                                padding-left: 3px;
                            }
                        }
                    }
                    .list{
                        margin-top: 20px;
                        color: #000;
                        height: 240px;
                        overflow: auto;
                        .gray{
                            color: #969697;
                        }
                        .item{
                            display: flex;
                            justify-content: space-between;
                            padding: 8px 0;
                            .right{
                                span{
                                    padding: 0 3px;
                                }                
                            }
                            
                        }
                        .loading, .nomore{
                            text-align: center;
                            color: gray;
                        }
                    }
                }
                .details{
                    .title{
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        padding-bottom: 10px;
                        display: flex;
                        align-items: center;
                        i{
                            padding-right: 5px;
                            font-size: 22px;
                        }
                    }
                    &-content{
                        padding: 20px;
                        color: #333;
                        font-size: 16px;
                        .date{
                            padding-top: 15px;
                            font-size: 12px;
                            color: #666;
                        }
                        .readonly {
                            .el-upload--picture-card:not(.el-upload-list) {
                                display: none;
                            }
                        }
                    }
                    &-response{
                        padding-left: 20px;
                        .title{
                            font-size: 16px;
                            font-weight: bold;
                            color: $main;
                            padding-bottom: 10px;
                        }
                        .res-content{
                            padding: 10px 15px;
                            background-color: #F9F9F9;
                            font-size: 16px;
                            color: #333;
                            .date{
                                padding-top: 15px;
                                font-size: 12px;
                                color: #666;
                            }
                        }
                    }
                }
                .add-item {
                    margin-top: 15px;
                    display: flex;
                }
                .add-title {
                    padding-top: 5px;
                    margin-right: 10px;
                    width: 112px;
                    text-align: right;
                }
                .add-content {
                    width: 100%;
                }
            }
            .image{
                padding: 30px 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

</style>