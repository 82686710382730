<style lang="scss">
    @import "~@/assets/css/var";
    .my-drawer {
        &__title {
            border-left: 4px solid $main;
            display: inline-block;
            font-size: 16px;
            line-height: 20px;
            margin-top: 4px;
            padding-left: 10px;
        }
        .el-link {
            font-size: 12px;
        }
        .el-drawer {
            transition: width .3s;
        }
        .el-drawer__header {
            display: block;
            padding: 10px;

            span {
                font-size: 18px;
                float: right;
            }
            i {
                padding-left: 10px;
                cursor: pointer;
            }
            .el-icon-full-screen {
                &:hover {
                    color: $main;
                }
            }
            .el-icon-close {
                &:hover {
                    color: $red;
                }
            }
        }
        .el-drawer__body {
            /*display: flex;*/
            overflow: auto;
            padding: 0 10px;
        }


        .tag-container {
            padding-top: 14px;

            p {
                font-size: 15px;
            }
        }
        .el-badge {
            margin: 5px 15px;
        }
    }
    .ts-form {
        $tableBackColor: #fafafa;
        box-sizing: border-box;
        border-left: 1px solid $border;
        border-top: 1px solid $border;
        position: relative;

        &::before, &::after {
            content: '';
            display: table;
        }

        &::after {
            clear: both;
        }

        .el-form-item {
            position: relative;
            display: inline-block;
            float: left;
            width: 50%;
            margin-bottom: 0;
            border-bottom: 1px solid $border;
            box-sizing: border-box;

            &__label {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                background: $tableBackColor;
                line-height: 29px;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border-right: 1px solid $border;
                padding-left: 12px;
            }

            .el-input__inner {
                height: 22px;
                line-height: 22px !important;
                padding: 0 10px;
            }
            .el-link {
                font-size: 12px;
            }

            .el-date-editor--date, .el-date-editor--datetime {
                .el-input__inner {
                    padding: 0 30px;
                }
            }

            &__content {
                display: flex;
                padding: 0 12px;
                line-height: 29px;
                min-height: 29px;
                font-size: 12px;
                color: #7F7F7F;
                /*overflow: hidden;*/
                /*text-overflow: ellipsis;*/
                /*text-align: left;*/
                /*white-space: nowrap;*/
                border-right: 1px solid $border;

                > [class^="el-"] {
                    width: 100%;
                }

                > .el-button {
                    width: auto;
                }

                .el-radio__label {
                    font-size: 12px;
                }
            }

            &.tr {
                width: 100%;
            }

            &.td-full {
                display: flex;

                .el-form-item__label {
                    flex-shrink: 0;
                }

                .el-form-item__content {
                    overflow: unset;
                    text-overflow: unset;
                    white-space: unset;
                    margin-left: 0 !important;
                    flex-grow: 1;
                }
            }

            .el-range-editor {
                width: 75%;
            }

            .num-range {
                width: 75%;
                display: flex;

                .el-input {
                    flex-grow: 1;
                }
            }
        }

        .el-textarea {
            margin-top: 6px;
        }

        .el-input-group__append, .el-input-group__prepend {
            padding: 0 10px;
        }

        .el-input-group {
            margin-top: 4px;
        }

        textarea {
            resize: none;
            overflow-y: auto;
        }

        .el-input-number--mini .el-input-number__increase, .el-input-number--mini .el-input-number__decrease {
            top: 3px;
            line-height: 20px;
        }
    }
</style>
<template>
    <el-drawer class="my-drawer" :visible.sync="show" v-loading="loading" :size="max ? '100%' : size" :show-close="false" :before-close="close">
        <template slot="title">
            <span>
                <i class="el-icon-full-screen" @click="max = !max"></i>
                <i class="el-icon-close" @click="close"></i>
            </span>
        </template>

        <div style="text-align: center;margin-top: 50px;" v-if="error">
            <img src="//img.yzcdn.cn/vant/empty-image-default.png" alt="">
            <div style="font-size:14px">
                暂无权限查看，请<el-link @click="$router.push('/info')" :underline="false" type="primary">登录会员</el-link>
            </div>
        </div>
        <template v-else>
            <div class="flex-item" style="">
                <el-form class="ts-form" label-width="120px">
                    <el-form-item v-for="item in list" :key="item.id" :label="item.viewName || item.name">
                        <template v-if="item.to_file_type === '文件'">
                            <el-link size="mini" target="_blank" type="primary" v-show="data[item.fieldName]"
                                     :href="data[item.fieldName]" :underline="false" style="justify-content:unset">查看</el-link>
                        </template>

                        <template v-else-if="item.to_file_type === '富文本'">
                            <el-button v-show="data[item.fieldName]" type="text" @click="showHtml(data[item.fieldName])">查看</el-button>
                        </template>

                        <template v-else-if="data[item.fieldName] && data[item.fieldName].length > 80">
                            <el-button type="text" @click="showContent(data[item.fieldName])">查看</el-button>
                        </template>

                        <template v-else>{{data[item.fieldName]}}</template>
                    </el-form-item>
                </el-form>
            </div>
            <div class="tag-container" v-show="tags.length > 0">
                <p>按药品（{{data.com_name}}）：</p>
                <el-badge v-for="item in tags" :key="item.table_name" :value="item.total" :max="99">
                    <el-button type="primary" @click="goPage(item)">{{item.name}}</el-button>
                </el-badge>
            </div>
            <div class="tag-container" v-show="corpTags.length > 0">
                <p>按企业（{{data.comp_name}}）：</p>
                <el-badge v-for="item in corpTags" :key="item.table_name" :value="item.total" :max="99">
                    <el-button type="primary" @click="goCorpPage(item)">{{item.name}}</el-button>
                </el-badge>
            </div>
        </template>

        <el-dialog title="详情" :visible.sync="dialog.show"  append-to-body>
            {{dialog.content}}
        </el-dialog>
    </el-drawer>
</template>

<script>
    import api from '@/util/api';
    export default {
        props: {
            show: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: '70%'
            },
            //表名
            name: {},
            // cols: {
            //     type: Array,
            //     default: () => {
            //         return []
            //     }
            // },
            rowId: {}
        },
        watch: {
            show() {
                if (this.show) {
                    this.cols = [];
                    this.tags = [];
                    this.corpTags = [];

                    this.loading = true;
                    this.error = false
                    // 获取所有列
                    api.get('table-field', {tableName: this.name}).then(res => {
                        res.data = res.data.filter(item => item.detail_view === '是');
                        this.cols = res.data.sort((a, b) => {
                            return a.detail_sort - b.detail_sort;
                        });
                    })

                    api.get(`${this.name}/${this.rowId}`)
                        .then(res => {
                            if (res.code === 405) {
                                this.error = true
                            } else {
                                this.data = res;

                                if (this.name && this.data.com_name) {
                                    api.get('drugs_index/statistic', {
                                        com_name: this.data.com_name,
                                        exclude_table: this.name
                                    })
                                        .then(data => {
                                            if (data.code === 200 && data.data && data.data.length > 0) {
                                                this.tags = data.data;
                                            }
                                        })
                                }

                                if (this.name && this.data.comp_name) {
                                    api.get('comp_index/statistic', {
                                        comp_name: this.data.comp_name,
                                        exclude_table: this.name
                                    })
                                        .then(data => {
                                            if (data.code === 200 && data.data && data.data.length > 0) {
                                                this.corpTags = data.data;
                                            }
                                        })
                                }
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                }

            }
        },
        data() {
            return {
                max: false,
                cols: [],
                tags: [],
                data: {},
                loading: false,

                corpTags: [],

                dialog: {
                    show: false,
                    content: '',
                },

                error: false,
            }
        },
        computed: {
            list() {
                if (this.cols) {
                    let list = this.cols.filter(item => item.detail_view === '是');
                    return list.sort((a, b) => {
                        return a.detail_sort - b.detail_sort;
                    })
                }
                return [];
            },
        },
        methods: {
            close() {
                this.$emit('update:show', false);
                return false;
            },
            goPage(item) {
                const { href } = this.$router.resolve({
                    path: `/store/${item.table_name}`,
                    query: {
                        statistic_com_name: this.data.com_name
                    }
                });
                window.open(href, "_blank");
            },
            goCorpPage(item) {
                const { href } = this.$router.resolve({
                    path: `/store/${item.table_name}`,
                    query: {
                        statistic_comp_name: this.data.comp_name
                    }
                });
                window.open(href, "_blank");
            },
            showHtml(html) {
                this.$emit('showHtml', html);
            },
            showContent(txt) {
                this.dialog.show = true
                this.dialog.content = txt
            }
        }
    }
</script>