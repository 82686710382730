<style lang="scss">
@import "~@/assets/css/var";
.index-question {
    width: 100%;
    &__title {
        margin-top: 18px;
        display: flex;

        .one {
            flex: 1;
            line-height: 39px;
            color: #fff;
            background: #A9B7FF;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            border-right: 1px solid #fff;
            cursor: pointer;

            &:first-child {
                border-left: 1px solid #fff;
            }

            &.active, &:hover {
                background: $main;
            }
            &.active {
                cursor: default;
            }
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;
        height: 165px;
        align-content: baseline;
    }
    &__item {
        box-sizing: border-box;
        display: flex;
        width: 50%;
        position: relative;
        padding-left: 30px;
        padding-right: 90px;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            background: #444;
            border-radius: 50%;
        }
        .txt {
            flex: 1;
            font-size: 14px;
            line-height: 40px;
            color: #5C5A5A;
            cursor: pointer;

            &:hover {
                color: $main;
                text-decoration: underline;
            }
        }
        .time {
            font-size: 14px;
            line-height: 40px;
            color: #737272;
        }
    }
}
</style>

<template>
    <div class="index-question">
        <div class="index-question__title">
            <div class="one" v-for="item in list" :key="item.id" :class="{active: item.id === model.id}"
                 @click="modelChange(item)">{{ item.classify }}</div>
        </div>

        <div class="index-question__list">
            <div class="index-question__item" v-for="item in model.list" :key="item.id">
                <span class="txt ellipsis" @click="goArticle(item)">{{item.question}}</span>
                <span class="time">{{item.createdAt.split(' ')[0]}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import router from "@/config/router";

export default {
    props: ['list', 'parent'],
    data() {
        return {
            model: {},
        }
    },
    created() {
        this.modelChange(this.list[0])
    },
    methods: {
        modelChange(obj) {
            if (this.model === obj.id) return false
            this.model = obj

            if (!this.model.loaded) {
                this.$set(this.model, 'loaded', true)

                this.$api.post('help/helpCenter/queryHelpCenterPage', {
                    page: {pageNo: 1, pageSize: 10},
                    params: {
                        classifyId: this.model.id,
                    },
                    sort: {
                        createdAt: 'desc'
                    },
                })
                    .then(res => {
                        this.$set(obj, 'list', res.data.records)
                    })
            }
        },
        goArticle(article) {
            const { href } = router.resolve({
                path: `/help-list?classifyId=${this.parent.id}&childId=${this.model.id}&articleId=${article.id}`,
            });
            window.open(href, "_blank");
        },
    }
}
</script>