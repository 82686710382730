<style lang="scss">
    @import "~@/assets/css/var";
    .ts-search {
        * {
            border-radius: 0;
        }
        .input-with-select {
            width: 300px;
            input {
                &:focus {
                    border-color: #A7A7A7 !important;
                }
            }
        }
        .el-select {
            position: relative;
            z-index: 1;
            width: 138px;
            margin-right: -1px;
        }

        .el-button {
            width: 100px;
            margin-left: -1px;
            background: $main;
            color: #fff;
            border-color: $main;
            vertical-align: 1px;
            &:hover {
                opacity: .7;
            }
        }
    }

    .kind-select {
        margin: 0 !important;
        .popper__arrow {
            display: none;
        }
        ul {
            padding: 0;
        }
        li {
            border-bottom: 1px solid #E4E4E4;
            min-width: 138px;
            line-height: 37px;
            font-size: 12px;
            padding: 0;
            color: #666;
            text-align: center;
            &.selected {
                color: #fff;
                background: $main;
            }
        }
    }
</style>

<template>
    <div class="ts-search">
        <el-select popper-class="kind-select" v-model="kind" placeholder="请选择" size="small" @change="typeChange">
            <el-option v-for="item in kinds" :key="item.key" :label="item.value" :value="item.key"></el-option>
        </el-select>
        <el-autocomplete :placeholder="myPlaceholder" size="small" class="input-with-select" :fetch-suggestions="suggest" v-model="txt" @select="selectSearchFunc"
                         @keyup.enter.native="search()" :value-key="variable.indexType.CORP === kind ? 'compName' : 'comName'">
            <template v-slot="{ item }">
                <el-tag style="margin-right: 10px;" v-show="item.accurate" type="success" disable-transitions>精确</el-tag>
                <span>{{variable.indexType.CORP === kind ? item.compName : item.comName}}
                    <!--<span>{{showAssist(item)}}</span>-->
                </span>
            </template>
        </el-autocomplete>
        <el-button size="small" @click="search()">全站搜索</el-button>
    </div>
</template>

<script>
    import variable from '@/util/variable';
    export default {
        props: ['param'],
        data() {
            return {
                variable,
                txt: '',
                kind: 1,
                kindName: '',
                kinds: [
                    {key: variable.indexType.DRUG, value: '药品'},
                    {key: variable.indexType.CORP, value: '企业'},
                    {key: variable.indexType.ASSIST, value: '用药助手'},
                ],
                history: [],
            }
        },
        created() {
            this.kind = this.param.type || variable.indexType.DRUG
            this.txt = this.param.txt || ''
            this.typeChange(true)
        },
        computed: {
            myPlaceholder() {
                if (this.kind === this.$variable.indexType.DRUG) {
                    return '请输入药品关键字'
                } else if(this.kind === this.$variable.indexType.CORP) {
                    return '请输入企业关键字'
                } else {
                    return '请输入药品关键字'
                }
            },
        },
        methods: {
            typeChange(isCreated) {
                if (!isCreated) {
                    this.txt = ''
                }

                switch (this.kind) {
                    case variable.indexType.DRUG:
                        this.kindName = 'drug'
                        break
                    case variable.indexType.CORP:
                        this.kindName = 'corp'
                        break
                    case variable.indexType.ASSIST:
                        this.kindName = 'assist'
                        break
                }
                this.getHistory()
            },
            selectSearchFunc(item) {
                this.txt = item[this.$variable.indexType.CORP === this.kind ? 'compName' : 'comName']
                this.search(item.accurate)
            },
            search(fullFlag) {
                const field = this.$variable.indexType.CORP === this.kind ? 'compName' : 'comName'
                const arr = this.history.map(item => item[field] + (item.accurate ? this.$variable.minSplitStr : ''))
                if (!arr.includes(this.txt + (fullFlag ? this.$variable.minSplitStr : ''))) {
                    const obj = {
                        accurate: !!fullFlag
                    }
                    obj[field] = this.txt
                    this.history.unshift(obj)
                    this.setHistory(fullFlag)
                }
                let path = `/${this.kindName}/${this.$help.encryption(this.txt)}`

                if (this.kindName === 'drug' && fullFlag) {
                    path += `?accurate=1`
                }

                this.$router.push({
                    path,
                })
                let text = ''
                if(this.kindName === 'drug'){
                    text = '药品搜索-'
                }else if(this.kindName === 'corp'){
                    text = '企业搜索-'
                }else if(this.kindName === 'assist'){
                    text = '用药助手搜索-'
                }
                // 改变标题
                this.$help.setTitle(text + this.txt)
            },

            getHistory() {
                const str = this.$help.store.get(this.kindName + 'History')
                if (str) {
                    const history = str.split(this.$variable.splitStr).map(item => {
                        const obj = {}
                        const arr = item.split(this.$variable.minSplitStr)
                        obj[this.$variable.indexType.CORP === this.kind ? 'compName' : 'comName'] = arr[0]

                        if (arr.length > 1) {
                            obj.accurate = true
                            obj.accurateShow = true
                        }

                        return obj
                    })
                    if (history.length > 10) {
                        this.history = history.splice(0, 10)
                    } else {
                        this.history = history
                    }
                } else {
                    this.history = []
                }
            },
            setHistory() {
                if (this.txt) {
                    this.$help.store.set(this.kindName + 'History', this.history
                        .map(item => {
                            let str = item[this.$variable.indexType.CORP === this.kind ? 'compName' : 'comName']
                            if (item.accurate) {
                                str += this.$variable.minSplitStr
                                str += this.$variable.suffix
                            }
                            return str
                        })
                        .join(this.$variable.splitStr)
                    )
                }
            },

            suggest(str, cb) {
                if (str) {
                    const param = {keyword: str}
                    switch (this.kind) {
                        case variable.indexType.DRUG:
                            this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', param).then(res => {
                                cb(res.data.map(item => {
                                    item.accurate = true
                                    return item
                                }))
                            });
                            break;
                        case variable.indexType.CORP:
                            this.$api.get('setting/aimBaseComp/queryAimBaseCompListByKeyword', param).then(res => {
                                cb(res.data);
                            });
                            break;
                        case variable.indexType.ASSIST:
                            this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', param).then(res => {
                                cb(res.data);
                            });
                            break;
                    }
                } else {
                    cb(this.history)
                }
            },
            // showAssist(item) {
            //     switch (this.kind) {
            //         case variable.indexType.DRUG:
            //             return item.key_name;
            //         case variable.indexType.CORP:
            //             return item.group_name;
            //         case variable.indexType.ASSIST:
            //             return item.comp_name;
            //     }
            // },
        }
    }
</script>