<style lang="scss">
@import "~@/assets/css/var";

.gov {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;
    height: 40px;
    flex-shrink: 0;
    border-top: 1px solid $border;

    img {
        float: left;
        height: 13px;
        width: unset;
    }

    a {
        display: inline-block;
        text-decoration: none;
        line-height: 11px;
        font-size: 11px;
    }

    p {
        float: left;
        height: 11px;
        line-height: 11px;
        margin: 0 0 0 5px;
    }
}
</style>
<template>
    <div class="gov">
        <img src="@/assets/img/police.png">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010702007585">
            <p>
                沪公网安备 31010702007585号 沪ICP备2022033787号-1
            </p>
        </a>
        <span style="margin-left: 10px;">互联网药品信息服务资格证书 (沪)-经营性-2022-0200</span>
        <div style="margin-left: 10px;">
            <el-button type="primary" icon="el-icon-info" @click="show = true">免责声明</el-button>
        </div>

        <ts-dialog :show.sync="show" title="免责声明" hide-footer width="550px">
            <p style="padding: 30px 20px;">
                本网站数据均为基于公开数据整理计算结果，我们力求但不保证数据的准确性和完整性，请谨慎参考。本网站任何数据或者意见不构成对任何人的投资或者决策建议。使用者应当自主决策，风险自担。</p>
        </ts-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            show: false
        }
    },
}
</script>