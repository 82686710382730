<template>
    <div class="calculator" id="calculator">
        <div class="calculator-border">
            <div class="calculator-display">
                <div class="calculator-formula" v-cloak>{{ formula }}</div>
                <div class="calculator-result" v-cloak>{{ result }}</div>
            </div>

            <div class="calculator-items">
                <div class="calculator-row-1">
                    <div class="calculator-item" @click="operate(7)">7</div>
                    <div class="calculator-item" @click="operate(8)">8</div>
                    <div class="calculator-item" @click="operate(9)">9</div>
                    <div class="calculator-item blue" @click="operate('/')">÷</div>
                    <div class="calculator-item red" @click="cleanAll()">CE</div>
                </div>

                <div class="calculator-row-2">
                    <div class="calculator-item" @click="operate(4)">4</div>
                    <div class="calculator-item" @click="operate(5)">5</div>
                    <div class="calculator-item" @click="operate(6)">6</div>
                    <div class="calculator-item blue" @click="operate('*')">×</div>
                </div>

                <div class="calculator-row-3">
                    <div class="calculator-item" @click="operate(1)">1</div>
                    <div class="calculator-item" @click="operate(2)">2</div>
                    <div class="calculator-item" @click="operate(3)">3</div>
                    <div class="calculator-item blue" @click="operate('-')">-</div>
                </div>

                <div class="calculator-row-4">
                    <div class="calculator-item" style="width: 38%" @click="operate(0)">0</div>
                    <div class="calculator-item" @click="operate('.')">.</div>
                    <div class="calculator-item blue" @click="operate('+')">+</div>
                    <div class="calculator-item blue" style="margin-right: 0;" @click="equal()">=</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data () {
        return {
            formula: "",
            result: 0
        }
    },
    methods:{
        operate(element){
            this.formula += element
        },
        equal(){
            this.result = eval(this.formula)
        },
        cleanAll(){
            this.formula = ""
            this.result = 0
        },
        drop(){
            this.formula = this.formula.slice(0, -1)
        },
        toggle(){
            this.formula===""||this.formula.endsWith("+"||"-"||"*"||"/"||"%")? {} :  this.formula.startsWith("-")?this.formula = Math.abs(eval(this.formula)).toString(): this.formula="-("+this.formula+")";
            this.equal()
        }
    }
}
</script>

<style>
[v-cloak] { display: none; }
.calculator .calculator-border .calculator-display{
    border: 1px solid #b9b4b4;
    background-color: #f5f8f8;
    margin:1rem;
    height: 7rem;
    width: 92.3%;
    font-size: 2rem;
    -webkit-border-radius: 0.4rem;
    -moz-border-radius: 0.4rem;
    border-radius: 0.4rem;
    box-shadow: 0.1rem 0.1rem 0.5rem #b55858;
}
.calculator .calculator-border .calculator-formula{
    width: 100%;
    height: 42.9%;
    text-align: right;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    color: #40aeea;
    text-overflow: ellipsis;
    white-space:nowrap;
    overflow:hidden;
}
.calculator .calculator-border .calculator-result{
    width: 100%;
    height: 57.1%;
    text-align: right;
    box-sizing: border-box;
    padding: 10px;
    color: #555;
    text-overflow: ellipsis;
    white-space:nowrap;
    overflow:hidden;
}
.calculator .calculator-border .calculator-items{
    margin:3rem 1rem;
    text-align: left;
    width: 92.3%;
    height: auto;
}
.calculator .calculator-border .calculator-items .calculator-row-1,
.calculator .calculator-border .calculator-items .calculator-row-2,
.calculator .calculator-border .calculator-items .calculator-row-3,
.calculator .calculator-border .calculator-items .calculator-row-4{
    margin-bottom: 2.3%;
    width: 100%
}
.calculator .calculator-border .calculator-items .calculator-item{
    border: 1px solid #ccc;
    width: 17.666%;
    height: 4rem;
    display: inline-block;
    text-align: center;
    line-height: 4rem;
    margin-right: 2.1%;
    color: #333;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: all 0.1s;
    font-size: 16px;
}
.calculator .calculator-border .calculator-items .calculator-item.blue{
    color: #40aeea;
}
.calculator .calculator-border .calculator-items .calculator-item.red{
    color: #ee5541;
    font-size: 16px;
}
.calculator .calculator-border .calculator-items .calculator-item:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}
.calculator .calculator-border .calculator-items .calculator-item:active{
    transform: translate(0.05rem,0.05rem);

}
.calculator .calculator-border .calculator-row-1 .calculator-item:nth-child(5),
.calculator .calculator-border .calculator-row-2 .calculator-item:nth-child(5),
.calculator .calculator-border .calculator-row-3 .calculator-item:nth-child(5),
.calculator .calculator-border .calculator-row-4 .calculator-item:nth-child(5) ,
.calculator .calculator-border .calculator-row-5 .calculator-item:nth-child(3){
    margin-right: 0;
}
</style>