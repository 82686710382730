import Vue from 'vue';
import VueRouter from 'vue-router'
import Index from '@/pages/home/Index'
import Middle from '@/components/Middle'

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

function loadComponent(url) {
    return () => import(`@/pages/${url}`)
}

import MyNotice from '@/pages/home/MyNotice'
const routerIndex = [
    {
        path: '/',
        name: 'index',
        component: Index,
    },
    {
        path: '/my-notice',
        name: 'myNotice',
        component: MyNotice,
    },
    {
        path: '/drug-compare',
        name: 'drugCompare',
        component: loadComponent('home/DrugCompare'),
    },
    {
        path: '/star',
        name: 'star',
        component: loadComponent('home/MyStar'),
    },
    {
        path: '/note',
        name: 'note',
        component: loadComponent('home/MyNote'),
    },
    // 销售数据库
    {
        path: '/sale/nationwide',
        name: 'nationwide',
        component: loadComponent('sale/saleNationwide'),
        meta: { title: '全国销售数据库'}
    },
    {
        path: '/sale/terminal',
        name: 'terminal',
        component: loadComponent('sale/saleTerminal'),
        meta: { title: '终端销售数据库' }
    },
    {
        path: '/sale/province',
        name: 'province',
        component: loadComponent('sale/saleProvince'),
        meta: { title: '省份销售数据库' }
    },
    {
        path: '/nav',
        component: loadComponent('home/Nav'),
        meta: {
            nav: true
        },
        children: [
            {
                path: 'product',
                name: 'navProduct',
                component: loadComponent('home/NavProduct'),
                meta: {
                    nav: true
                },
            },
            {
                path: 'corp',
                name: 'navCorp',
                component: loadComponent('home/NavCorp'),
                meta: {
                    nav: true
                },
            },
            {
                path: 'service',
                name: 'navService',
                component: loadComponent('home/NavService'),
                meta: {
                    nav: true
                },
            },
            {
                path: 'analysis',
                name: 'navAnalysis',
                component: loadComponent('home/NavAnalysis'),
                meta: {
                    nav: true
                },
            },
            {
                path: '',
                redirect: 'product'
            },
        ],
    },
    {
        path: '/nav-all',
        name: 'navAll',
        meta: {
            noFull: true,
        },
        component: loadComponent('home/NavAll'),
    }
]
const routerAuth = [
    // 登录页
    {
        path: '/login',
        name: 'login',
        component: loadComponent('auth/Login')
    },
    // 注册页
    {
        path: '/register',
        name: 'register',
        component: loadComponent('auth/Register')
    },
    // 找回密码
    {
        path: '/find',
        name: 'find',
        component: loadComponent('auth/Find')
    },
]
const routerMember = [
    // 会员
    {
        path: '/members',
        name: 'members',
        component: loadComponent('members')
    },
    // 消息中心
    {
        path: '/message',
        name: 'message',
        component: loadComponent('members/Message')
    },
]
const routerMake = [
    {
        path: '/make',
        name: 'make',
        component: loadComponent('make')
    },
]
const routerSearch = [
    {
        path: '/drug',
        name: 'drugNull',
        redirect: '/'
    },
    {
        path: '/drug/:txt',
        name: 'drug',
        meta: {
            path: '/drug'
        },
        component: loadComponent('search/Drug')
    },
    {
        path: '/corp',
        name: 'corpNull',
        redirect: '/'
    },
    {
        path: '/corp/:txt',
        name: 'corp',
        meta: {
            path: '/corp'
        },
        component: loadComponent('search/Corp')
    },
    {
        path: '/assist',
        name: 'assistNull',
        redirect: '/'
    },
    {
        path: '/assist/:txt',
        name: 'assist',
        meta: {
            path: '/assist'
        },
        component: loadComponent('search/Assist')
    },
]


import HelpIndex from '@/pages/help/Index'
import HelpList from '@/pages/help/HelpList'
import HelpSearchResult from '@/pages/help/HelpSearchResult'
const routerHelp = [
    {
        path: '/help',
        name: 'help',
        component: HelpIndex
    },
    {
        path: '/help-list',
        name: 'helpList',
        component: HelpList,
    },
    {
        path: '/help-result',
        name: 'helpResult',
        component: HelpSearchResult,
    },
]

import requirementIndex from '@/pages/requirement/Index'
const routerRequirement = [
    {
        path: '/requirement',
        name: 'requirement',
        component: requirementIndex
    },
]

const config = new VueRouter({
    routes: [
        ...routerIndex,
        ...routerAuth,
        ...routerMember,
        ...routerSearch,
        ...routerMake,
        ...routerHelp,
        ...routerRequirement,

        //招投标信息
        {   
            path: '/bidding-info-country',
            component: loadComponent('store/BiddingInfoCountry'),
        },
        {   
            path: '/bidding-info-province',
            component: loadComponent('store/BiddingInfoProvince'),
        },
        //药品成本测算
        {   
            path: '/cost-estimation-drugs',
            component: loadComponent('store/costEstimationDrugs'),
        },


        // 中药材价格
        {
            // todo 未加统计
            path: '/ch-medicinal-price',
            component: loadComponent('store/ChMedicinalPrice'),
        },

        // 报告列表
        {
            path: '/report',
            component: loadComponent('report/ReportIndex'),
        },
        {
            path: '/report/general',
            // component: loadComponent('report/general/indexTemp'),
            component: loadComponent('report/general/index'),
        },
        {
            path: '/report/general1',
            component: loadComponent('report/general/index'),
        },
        {
            path: '/report/self',
            component: loadComponent('report/ReportGeneralList'),
        },
        {
            path: '/report/:id',
            meta: {
                path: '/reportDetail'
            },
            component: loadComponent('report/ReportDetail'),
        },


        // 上传文献
        {
            path: '/upload',
            meta: {
                overflow: true
            },
            component: loadComponent('uploadDoc/Insert'),
        },
        {
            path: '/upload-record',
            component: loadComponent('uploadDoc/Record'),
        },

        // 数据分析
        {
            path: '/new',
            component: Middle,
            children: [
                {
                    path: 'aim_drugs_overview',
                    component: loadComponent('new/Overview'),
                },
                {
                    path: 'aim_drugs_treat_big',
                    component: loadComponent('new/TreatBig'),
                },
                {
                    path: 'aim_drugs_drug',
                    component: loadComponent('new/Drug'),
                },
                {
                    path: 'aim_drugs_corp',
                    component: loadComponent('new/Corp'),
                },
                {
                    path: 'aim_drugs_province',
                    component: loadComponent('new/Province'),
                },
                {
                    path: 'sale_terminal',
                    component: loadComponent('new/Terminal'),
                },
            ]
        },

        //穿透
        {
            path: '/through-dose',
            name: 'through-dose',
            component: loadComponent('through/dose')
        },
        {
            path: '/through-component',
            name: 'through-component',
            component: loadComponent('through/component')
        },
        {
            path: '/through-indication',
            name: 'through-indication',
            component: loadComponent('through/indication')
        },

        // 通用列表页面
        {
            path: '/store/:key',
            component: loadComponent('store/parent'),
        },

        // 其他路由，显示首页
        {
            path: '*',
            component: Index
        },
    ]
})

config.afterEach((to) => {
    // 设置背景
    setTimeout(() => {
        const appBody = document.getElementsByTagName('body')[0]
        if (to.meta.overflow) {
            appBody.style.overflow = 'auto'
        } else {
            appBody.style.overflow = ''
        }

        const appEl = document.getElementById('app')
        if (to.meta.noFull) {
            appEl.style.height = 'unset'
        } else {
            appEl.style.height = ''
        }

    }, 0)
})

export default config