<style lang="scss">
.my-star-drawer {
    width: 40% !important;
    .el-drawer__header {
        color: #4c4d4d;
        font-weight: bold;
        font-size: 16px;
    }

    .el-drawer__body {
        padding: 0 10px 20px;
        box-sizing: border-box;
    }
    .drawer-content_time{
        font-size: 12px;
        font-weight: bold;
    }
    .drawer-content_text {
        // text-indent: 2ch; /* 使用字符宽度单位 */
        margin-bottom: 10px;
        white-space: pre-line;
        padding-left: 10px;
    }
}
</style>
<template>
    <el-drawer custom-class="my-star-drawer" :title="drawerForm.title" :visible.sync="drawerForm.drawer"
        :direction="drawerForm.direction" :before-close="handleClose" append-to-body>
        <div class="drawer-content">
            <div v-for="(item, index) in drawerForm.list" :key="index">
                <div class="drawer-content_time">
                    <span>{{ item.createdAt }}</span>
                    <span style="margin-left: 10px;"> {{ item.userName }}</span>
                </div>
                <div class="drawer-content_text" v-html="item.context"></div>
                <el-divider></el-divider>
            </div>
        </div>
    </el-drawer>
</template>

<script>
export default {
    props: {
        drawerForm:{
            typeof:Object,
            default:()=>({
                title:'标题',
                drawer:false,
                direction:'rtl',
                list:[],
            })
        },
    },

    data() {
        return {

        }
    },

    methods: {
        handleClose(done) {
            done();
            this.$emit('update:drawer',false)
        },
    }
}
</script>