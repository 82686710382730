<style lang="scss">
    @import "~@/assets/css/var";
    /* 自定义全局加载样式 */

    $leftWidth: 200px;  //左侧宽度
    .master-header {
        line-height: 44px;
        background: $main;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        box-sizing: border-box;
        text-align: center;

        &__logo {
            width: $leftWidth;
            font-size: 20px;
            background: $mainDark;
            cursor: pointer;
            transition: width .38s;
            &:hover {
                background: transparent;
            }
            &.short {
                width: 65px;
            }
        }
        &__assist {
            width: 48px;
            background: $mainLight;
            cursor: pointer;
            i {
                transition: transform .38s;
                transform: rotate(-90deg);
            }
            &.short {
                i {
                    transform: rotate(0);
                }
            }
            &:hover {
                opacity: .7;
            }
        }
        &__menu {
            padding: 0 15px;
            .el-dropdown {
                color: #fff;
            }
        }
        &__login {
            cursor: pointer;
            padding: 0 15px;
            font-size: 20px;
            i {
                font-weight: bold;
            }
            &:hover {
                background: $red;
            }
        }
    }

    .master-left {
        background: #fafafa;
        * {
            font-size: 12px;
            font-weight: bold;
        }
        .el-menu {
            background: transparent;
        }
        li {
            border-bottom: 1px solid #ebedf1;
        }
        > .el-menu:not(.el-menu--collapse) {
            width: $leftWidth;
            border: 0;
            background: #fff;
            > .el-submenu {
                background: #fff;
            }
        }
        .is-opened, .is-active {
            background: #f5f5f5 !important;
        }
        .icon {
            color: #333;
            padding: 0 10px;
        }
        .el-menu-item {
            line-height: 49px;
            height: 49px;
        }
    }

    .master-body {
        margin: 10px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar { display: none; }  /* Chrome, Safari, Opera */
    }

</style>

<template>
    <div id="app" @mousedown="baseEvent">
        <template v-if="['register', 'login', 'find'].includes($route.name)">
            <router-view></router-view>
        </template>

        <template v-else>
            <Master></Master>
        </template>
    </div>
</template>

<script>
    import Master from '@/pages/Master'
    export default {
        name: 'App',
        components: {
            Master
        },
        methods: {
            baseEvent() {
                window.hideSearchTip && window.hideSearchTip()
            },
        }
    }
</script>
