/* eslint-disable */
window.removeFinished = true
export default {
    name: 'dragDiv',
    bind: function (el) {
        let oDiv = el;  // 获取当前元素
        oDiv.onmousedown = (e) => {
            // 算出鼠标相对元素的位置
            let disX = e.clientX - oDiv.offsetLeft;
            let disY = e.clientY - oDiv.offsetTop;

            document.onmousemove = (e) => {
                window.removeFinished = false
                // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                let left = e.clientX - disX;
                let top = e.clientY - disY;
                const screenWidth = window.innerWidth - oDiv.offsetWidth
                const screenHeight = window.innerHeight - oDiv.offsetHeight
                if (left < 0 || top < 0 || top > screenHeight || left > screenWidth) {
                    return;
                }
                oDiv.style.left = left + 'px';
                oDiv.style.top = top + 'px';
            };

            document.onmouseup = () => {
                setTimeout(() => {
                    window.removeFinished = true
                }, 100)
                document.onmousemove = null;
                document.onmouseup = null;
            };
            document.ondragstart = function (ev) {
                ev.preventDefault();
            };
            document.ondragend = function (ev) {
                ev.preventDefault();
            };
        }
    }
}