<template>
    <div class="arr" @click.stop="close(null)">
        <div class="context-menu" :style="{ top: top, left: left }">
            <ul>
                <li v-loading="item.loading" v-for="(item, index) in navList" :key="index" @click.stop="item.event(item.type,item)">
                    {{ item.text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        top: {
            type: String,
            required: true,
        },
        left: {
            type: String,
            required: true,
        },
        menuObj: {
            type: Object,
            required: () => { },
        },
    },
    watch: {
        menuObj: {
            handler(val) {
                console.log('watch menuObj', val);
                let end = new Date().getTime();
                let start = new Date(val.sendTime).getTime();
                let utc = end - start;
                let m = utc / (60 * 1000);
                if (!val.isMe || m > 2) {
                    this.navList.splice(0, 1);
                }
                if (val.messageType === 'VOICE') {
                    this.navList.push({ text: "转文字", type: 3, event: this.convertNewText ,loading:false});
                }
            },
            deep: true,
            immediate: true,
        },
    },
    data() {
        return {
            navList: [
                { text: "撤回", type: 1, event: this.msgRevoke },
                { text: "引用", type: 2, event: this.getQuote },
                // { text: "转文字", type: 3, event: this.convertNewText },
            ],
        };
    },
    methods: {
        //转文字
        convertNewText(type,orr) {
            orr.loading = true
            let _obj = this.$help.deepClone(this.menuObj)
            if (!this.menuObj.convertText) {
                this.$api.post("sys/SysChatMessages/speechToText", {
                    id: this.menuObj.id,
                }).then(res => {
                    if (res.success) {
                        if (res.data) {
                            _obj.convertText = res.data
                            _obj.isDisplayText = true
                            this.close(type, _obj);
                        } else {
                            return this.$store.commit('error', '暂无内容！')
                        }
                    }
                })
                .finally(()=>{
                    orr.loading = false
                })
            }else{
                orr.loading = false
                _obj.isDisplayText = true
                this.close(type, _obj);
            }
        },
        // 引用
        getQuote(type) {
            this.close(type, this.menuObj);
        },
        // 撤回
        msgRevoke(type) {
            this.$api
                .post("sys/SysChatMessages/withdrawMessage", {
                    clientId: this.$help.getClientId() || "",
                    userId: this.$store.state.user.id || "",
                    id: this.menuObj.id,
                })
                .then((res) => {
                    if (res.success) {
                        this.close(type, this.menuObj);
                    }
                });
        },
        close(type, val) {
            this.$emit("close", type, val);
        },
    },
};
</script>

<style lang="scss" scoped>
.arr {
    width: 100vw;
    height: 100vh;
    z-index: 1005;
    position: fixed;
    top: 0;
    left: 0;
}

.context-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 1006;
    list-style: none;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.context-menu li {
    padding: 8px 12px;
    cursor: pointer;
    user-select: none;
}

.context-menu li:hover {
    background-color: #f0f0f0;
}
</style>