<style lang="scss">
@import "~@/assets/css/var";
.help-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__panel {
        display: flex;
        flex: 1;
        width: 1200px;
        margin: 15px auto 25px;
    }
    &__left {
        border-radius: 4px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
        overflow-y: auto;
        width: 240px;
        box-sizing: border-box;

        .el-menu {
            border: 0;
            i {
                font-size: 16px;
                font-weight: 600;
            }
            .el-submenu__title {
                height: 46px;
                line-height: 46px;
                font-weight: bold;
            }
            .el-menu-item {
                font-size: 14px;
                line-height: 33px;
                height: 33px;
                text-align: center;
                border-radius: 6px;
                margin: 0 10px;

                &.is-active {
                    color: #fff;
                    background: $main;
                }
            }
            .left-icon {
                color: $main;
            }
        }
    }
    &__right {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 20px;
        border-radius: 4px;
        overflow-y: auto;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        padding: 40px;

        .list {
            height: 0;
            flex: 1;
            overflow-y: auto;
        }
        .item {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 28px;
            color: #504D4D;
            padding: 5px 0;
            border-bottom: 1px solid #D8D8D8;
            .txt {
                flex: 1;
                cursor: pointer;
                &:hover {
                    color: $main;
                }
            }
            .time {
                color: #999999;
            }
        }
        .page {
            padding-top: 10px;
            text-align: center;
        }

        .title {
            position: relative;
            font-size: 24px;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 32px;
            color: #535353;
            i {
                margin-right: 15px;
                color: $main;
            }
            span {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 12px;
                color: #666;
                font-weight: normal;
            }
        }
    }
}
</style>
<template>
    <div class="index-page help-index help-list">
        <div class="help-index__banner">
            <img src="@/assets/img/help-banner.png" alt="banner">
            <help-search></help-search>
        </div>

        <div class="my-notice" style="margin-top: 15px;">
            <p class="my-notice__desc">帮助中心 - 使用教程-更多</p>
        </div>

        <div class="help-list__panel">
            <div class="help-list__left">
                <el-menu :default-openeds="menuOpen" :default-active="menuActive" @select="menuChange">
                    <el-submenu v-for="item in list" :key="item.id" :index="item.id">
                        <template #title>
                            <i class="el-icon-share left-icon"></i>{{ item.classify }}
                        </template>
                        <el-menu-item v-for="child in item.children" :key="child.id" :index="child.id">{{ child.classify }}</el-menu-item>
                    </el-submenu>
                </el-menu>
            </div>
            <div class="help-list__right">
                <template v-if="right.isList">
                    <p class="title">{{ right.kind.classify }}</p>
                    <div class="list">
                        <div class="item" v-for="item in right.list" :key="item.id">
                            <span class="txt" @click="goDetail(item)">{{ item.question }}</span>
                            <span class="time">{{ item.createdAt.split(' ')[0] }}</span>
                        </div>
                    </div>
                    <div class="page">
                        <el-pagination layout="prev, pager, next, total" :total="right.total" :page-size="right.page.pageSize"
                                       @current-change="pageChange"></el-pagination>
                    </div>
                </template>
                <div v-else>
                    <p class="title">
                        <i class="el-icon-back clickable" @click="backList" v-show="!right.onlyDetail"></i>
                        {{ right.detail.question }}
                        <span>{{right.detail.createdAt}}</span>
                    </p>
                    <div class="content" v-html="right.detail.answer"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HelpSearch from './HelpSearch.vue'

export default {
    components: {HelpSearch},
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'helpList',
            pageName: '帮助列表',

            menuOpen: [],
            menuActive: '',

            list: [],
            menuMap: {},
            // 列表还是详情
            right: {
                isList: true,
                loading: false,

                kind: {},


                list: [],
                detail: {},
                onlyDetail: false,

                page: {
                    pageNo: 1,
                    pageSize: 10,
                },
                total: 0,
            },
        }
    },
    created() {
        this.$help.socket.send(this)

        // 加载所有分类
        this.$api.post('help/helpCenterClassify/queryHelpCenterClassifyTreeList', {
            sort: {orderIndex: 'asc'},

        })
            .then(res => {
                const list = res.data
                this.menuOpen = [this.$route.query.classifyId || list[0].id]
                if (this.$route.query.childId) {
                    this.menuActive = this.$route.query.childId
                } else {
                    this.menuActive = list.filter(item => item.id === this.menuOpen[0])[0].children[0].id
                }

                // 构造map
                list.forEach(item => {
                    item.children.forEach(child => {
                        this.menuMap[child.id] = child
                    })
                })

                this.list = list
                this.menuChange(this.menuActive)
            })
    },
    methods: {
        menuChange(menu) {
            if (menu) {
                this.right.isList = true
                this.right.list = []
                this.right.detail = {}
                this.right.onlyDetail = false
                this.right.kind = this.menuMap[menu]
                this.init()
            }
        },

        init() {
            this.right.page.pageNo = 1
            this.getList()
        },
        getList() {
            this.$api.post('help/helpCenter/queryHelpCenterPage', {
                page: this.right.page,
                params: {
                    classifyId: this.right.kind.id,
                },
                sort: {
                    createdAt: 'asc'
                },
            })
                .then(res => {
                    this.right.list = res.data.records
                    this.right.total = res.data.totalCount
                    this.right.onlyDetail = res.data.totalCount === 1

                    // 是否默认打开
                    const articleId = this.$route.query.articleId
                    if (articleId) {
                        const resArr = this.right.list.filter(item => item.id === articleId)

                        if (resArr.length > 0) {
                            const obj = resArr[0]
                            this.goDetail(obj)
                        }

                    }

                    // 如果只有一条，强制看详情
                    if (this.right.onlyDetail) {
                        this.right.isList = false
                        this.goDetail(this.right.list[0])
                    }
                })
        },
        pageChange(val) {
            this.right.page.pageNo = val
            this.getList()
        },

        goDetail(item) {
            this.right.isList = false
            this.right.detail = item
        },
        backList() {
            this.right.isList = true
            this.right.detail = {}
        },
    }
}
</script>