<style lang="scss">
@import "~@/assets/css/var";
.help-index {
    &__banner {
        position: relative;
        .el-autocomplete {
            position: absolute;
            bottom: 15%;
            left: 50%;
            width: 600px;
            transform: translateX(-50%);

            .el-input-group__append {
                background: $main;
                color: #fff;
                border-color: $main;
                cursor: pointer;
                &:hover {
                    background: $mainLight;
                    border-color: $mainLight;
                }
            }
        }
    }

    &__panel {
        width: 1200px;
        margin: 0 auto;
        padding: 45px 0;

        .container {
            padding: 0 20px 10px;
            border-radius: 16px;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
            & + .container {
                margin-top: 45px;
            }
        }

        .title {
            display: flex;
            font-size: 26px;
            line-height: 38px;
            font-weight: 500;
            padding-top: 45px;
            color: #4A4A4A;
            .assist {
                font-size: 16px;
                font-weight: normal;
                color: #737373;
            }
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            padding: 8px;
            box-sizing: border-box;
            align-content: baseline;
        }
        .item {
            position: relative;
            box-sizing: border-box;
            width: 268px;
            margin: 13px 8px 0;
            border-radius: 10px;
            cursor: pointer;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15),inset 0 0 9px 1px rgba(220, 226, 255, 0.3);
            transition: background-color .3s;

            * {
                transition: color .3s;
            }

            &:hover {
                background: $main;
                * {
                    color: #fff !important;
                }
            }

            .img {
                background: #F6F7F9;
                border-radius: 50%;
                font-size: 0;
                padding: 8px;
            }

            &.easy {
                display: flex;
                align-items: center;
                padding: 28px 22px;
                img {
                    width: 37px;
                    height: 37px;
                }
                .txt {
                    margin-left: 24px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #444444;
                }
            }
            &.complex {
                display: flex;
                align-items: center;
                padding: 32px 15px;
                margin-top: 25px;

                img {
                    width: 34px;
                    height: 34px;
                }
                .img {
                    margin-right: 6px;
                }
                .complex-title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 23px;
                    color: #444444;
                    text-align: right;
                    margin-bottom: 3px;
                }
                .complex-assist {
                    font-size: 14px;
                    line-height: 20px;
                    color: #626262;
                    text-indent: 28px;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
                .complex-see {
                    position: absolute;
                    bottom: 8px;
                    right: 18px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #19191A;
                }
            }
        }

        .business {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 28px;

            &-card {
                position: relative;
                width: 240px;
                height: 146px;
                border-radius: 8px;
                padding: 22px 17px 33px;
                box-sizing: border-box;
                background: url('~@/assets/img/help/business-card.png') no-repeat center / 100% 100% !important;

                img {
                    position: absolute;
                    top: 22px;
                    right: 17px;
                    width: 49px;
                    height: 49px;
                }

                & + .business-card {
                    margin-left: 8px;
                }
            }

            .name {
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
                color: #4D4D4D;
            }
            .post {
                font-size: 12px;
                line-height: 17px;
                color: #565656;
                padding-bottom: 15px;
            }
            .contact {
                font-size: 12px;
                line-height: 14px;
                color: #565656;
            }
            .qrcode {
                width: 76px;
                margin: 0 20px;
            }
            .wechat {
                width: 76px;
            }
            .phone {
                font-size: 26px;
                line-height: 44px;
                text-align: center;
                color: #4D4D4D;
            }
            .num {
                font-size: 26px;
                line-height: 44px;
                text-align: center;
                color: #4D4D4D;
            }
        }
    }
}
</style>

<template>
    <div class="index-page help-index">
        <div class="help-index__banner">
            <img src="@/assets/img/help-banner.png" alt="banner">
            <help-search></help-search>
        </div>

        <div class="help-index__panel">
            <div class="container">
                <template v-for="item in list">
                    <div class="title" :key="item.id + '_title'">
                        <span class="flex-item">{{ item.classify }}</span>
                        <span class="clickable assist" @click="goMore(item)">更多>></span>
                    </div>

                    <div v-if="item.style === 'easy'" class="list" :key="item.id + '_easy'" style="min-height: 260px">
                        <div class="item easy" v-for="child in item.children" :key="child.id" @click="goChild(child, item)">
                            <div class="img"><img src="/img/help/drug.png" alt=""></div>
                            <div class="txt ellipsis">{{ child.classify }}</div>
                        </div>
                    </div>

                    <div v-else-if="item.style === 'complex'" class="list" :key="item.id + '_complex'" style="min-height: 374px">
                        <div class="item complex" v-for="child in item.children" :key="child.id" @click="goChild(child, item)">
                            <div class="img"><img src="/img/help/drug.png" alt=""></div>
                            <div style="height: 90px">
                                <p class="complex-title ellipsis">{{child.classify}}</p>
                                <p class="complex-assist" :title="child.description">{{child.description}}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="item.style === 'question'" class="list" :key="item.id + '_question'">
                        <index-question v-if="item.children && item.children.length > 0" :parent="item" :list="item.children"></index-question>
                    </div>
                </template>
            </div>

            <div class="container">
                <div class="title">
                    <span class="flex-item">商务合作</span>
                </div>

                <div class="business">
                    <div class="business-card">
                        <p class="name">刘经理</p>
                        <p class="post">数据服务</p>
                        <p class="contact">电话：15079003379</p>
                        <img src="@/assets/img/help/person.png">
                    </div>
                    <div class="business-card">
                        <p class="name">徐经理</p>
                        <p class="post">数据服务</p>
                        <p class="contact">电话：15279235297</p>
                        <img src="@/assets/img/help/person.png">
                    </div>
                    <div class="business-card">
                        <p class="name">曹经理</p>
                        <p class="post">数据服务</p>
                        <p class="contact">电话：18279162887</p>
<!--                        <p class="contact">QQ：242862340</p>-->
                        <img src="@/assets/img/help/person.png">
                    </div>

                    <img class="qrcode" src="@/assets/img/help/qrcode.png">
                    <img class="wechat" src="@/assets/img/help/taoshu.png">

                    <div style="padding-left: 25px;">
                        <p class="phone">热线电话</p>
                        <p class="num">400-8676768</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import IndexQuestion from './IndexQuestion.vue'
import HelpSearch from './HelpSearch.vue'
import router from "@/config/router";

export default {
    components: {
        IndexQuestion, HelpSearch
    },
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'helpIndex',
            pageName: '帮助首页',

            list: [],
        }
    },
    created() {
        this.$help.socket.send(this)


        this.$api.post('help/helpCenterClassify/queryHelpCenterClassifyTreeList', {
            sort: {orderIndex: 'asc'},
            params: {
                showHome: '是',
            },
        })
            .then(res => {
                this.list = res.data
            })
    },
    methods: {
        goMore(item) {
            const { href } = router.resolve({
                path: `/help-list?classifyId=${item.id}`,
            });
            window.open(href, "_blank");
        },
        goChild(item, parent) {
            const { href } = router.resolve({
                path: `/help-list?classifyId=${parent.id}&childId=${item.id}`,
            });
            window.open(href, "_blank");
        },
    }
}
</script>