<template>
    <!--<div style="padding: 15px;width: unset;" ref="ue"></div>-->
    <vue-ueditor-wrap v-model="model" :config="ueConfig"></vue-ueditor-wrap>
</template>

<script>
    import VueUeditorWrap from 'vue-ueditor-wrap'
    export default {
        components: {
            VueUeditorWrap
        },
        data() {
            return {
                isLoad: false,
                ue: null,
                model: '',
                ueConfig: {
                    autoHeightEnabled: false, //编译器不自动被内容撑高
                    initialFrameHeight: 350, //初始容器高度
                    initialFrameWith: "100%",
                    serverUrl: "/taoshu-data/ueditor.do", //上传文件地址
                    UEDITOR_HOME_URL: `//data.taoshu.com.cn/ueditor/`, //UEditor资源文件的存放路径
                }
            }
        },
        created() {
            // window.UEDITOR_CONFIG.serverUrl = '/ueditor/server'
        },
        methods: {
            show(content) {
                this.model = content
            },
            get() {
                return this.model
            },
        }
    }
</script>