<style lang="scss">
@import "~@/assets/css/var";
.my-comment__list {
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 270px;
  padding: 10px 10px;
  overflow-y: auto;
  //background: #eee;
  .bottom {
    text-align: center;
    padding-top: 300px;
    color: #999;
  }
}
.my-comment__item {
  margin: 0 10px 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  .name {
    color: $main;
  }
  .desc {
    color: #000;
    padding: 10px 0 0;
    white-space: pre-wrap;
  }
}
.clear-drawer__operate {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 20px 20px;
  background: #fff;

  .el-pagination {
    display: flex;
    padding: 0;
  }
  .el-radio-group {
    margin-right: 15px;
  }
  .el-radio-button__inner {
    height: unset !important;
    line-height: unset !important;
    font-size: 12px !important;
  }
}
</style>

<template>
    <clear-drawer :show.sync="show" :title="title" size="600px" hide-max>
        <div class="my-comment__list">
            <div class="my-comment__item" v-for="item in list" :key="item.id">
                <p class="name">
                    {{item.userName}} {{item.createdAt}}
                </p>
                <p class="desc" v-html="item.context"></p>
            </div>

            <div class="bottom" v-show="loading"><i class="el-icon-loading"></i> 加载中...</div>
            <div class="bottom" v-show="!loading && list.length === 0">暂无用户评论</div>
        </div>

        <div class="clear-drawer__operate">
            <el-pagination :current-page.sync="page" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                           layout="slot, total, prev, pager, next" :total="total"
                           @size-change="handleSizeChange" @current-change="handleCurrentChange">
                <el-radio-group v-model="isSelf" size="mini" @change="radioChange">
                    <el-radio-button :label="false">全部评论</el-radio-button>
                    <el-radio-button :label="true">仅看自己</el-radio-button>
                </el-radio-group>
                <div class="flex-item"></div>
            </el-pagination>
            <div v-if="!$store.state.comment.read" style="margin: 15px 0;">
                <el-input type="textarea" placeholder="请输入评论内容" :rows="8" resize="none" v-model="txt" show-word-limit></el-input>
            </div>
            <div v-if="!$store.state.comment.read" style="text-align: right">
                <el-button @click="txt = ''">清空</el-button>
                <el-button @click="addComment" :loading="saveLoading" type="primary">评论</el-button>
            </div>
        </div>
    </clear-drawer>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            param: {},

            loading: false,
            list: [],
            page: 1,
            pageSize: 20,
            total: 0,
            saveLoading: false,

            txt: '',

            isSelf: false,

            needLoad: false,
            title: '',
        }
    },
    watch: {
        show() {
            if (!this.show) {
                this.$store.commit('hideComment')
            }
        },
        '$store.state.comment.show': {
            handler(val) {
                this.show = val

                if (val) {
                    this.param = this.$help.deepClone(this.$store.state.comment)
                    delete this.param.show
                    delete this.param.read

                    const type = this.param.type

                    if (type === '0') {
                        this.title = `${this.param.comName}-用户评论`
                    } else if (type === '1') {
                        this.title = `${this.param.compName}-用户评论`
                    } else if (type === '2') {
                        this.title = `${this.param.compName}-${this.param.comName}-用户评论`
                    }
                    this.init()
                } else {
                    this.needLoad && this.$store.state.comment.cb && this.$store.state.comment.cb()
                }
            },
            immediate: true,
        },
    },
    methods: {
        init() {
            this.txt = ''
            this.loading = false
            this.page = 1
            this.list = []
            this.finished = false
            this.needLoad = false

            this.getList()
        },
        radioChange() {
            this.init()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.handleCurrentChange(1)
        },
        handleCurrentChange(page) {
            this.page = page
            this.getList()
        },
        getList() {
            this.loading = true
            const param = this.$help.deepClone(this.param)
            this.$api.post('sys/sysUserAppraise/querySysUserAppraisePage', {
                params: param,
                model: this.isSelf,
                page: {
                    pageNo: this.page,
                    pageSize: this.pageSize,
                },
                sort: {createdAt: 'desc'}
            })
                .then(res => {
                    if (res.success) {
                        this.total = res.data.totalCount
                        this.list = res.data.records
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        addComment() {
            if (!this.txt) {
                this.$store.commit('error', '请输入评论内容')
                return false
            }
            const param = this.$help.deepClone(this.param)
            param.context = this.txt
            this.saveLoading = true
            this.$api.post('sys/sysUserAppraise/insertSysUserAppraise', param)
                .then(res => {
                    if (res.success) {
                        this.$store.commit('success', '评论成功')
                        this.getList()
                        this.txt = ''
                        this.needLoad = true
                    } else {
                        this.$store.commit('error', res.message)
                    }
                })
                .finally(() => {
                    this.saveLoading = false
                })
        },
    },
}
</script>