<template>
    <clear-drawer :show.sync="show" :title="title" size="600px" hide-max>
        <div class="my-comment__list">
            <div class="my-comment__item" v-for="item in list" :key="item.id">
                <p class="name">
                    {{item.userName}} {{item.createdAt}}
                </p>
                <p class="desc" v-html="item.context"></p>
            </div>

            <div class="bottom" v-show="loading"><i class="el-icon-loading"></i> 加载中...</div>
            <div class="bottom" v-show="!loading && list.length === 0">暂无用户笔记</div>
        </div>

        <div class="clear-drawer__operate">
            <el-pagination :current-page.sync="page" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                           layout="total, prev, pager, next" :total="total"
                           @size-change="handleSizeChange" @current-change="handleCurrentChange">
                <div class="flex-item"></div>
            </el-pagination>
            <div v-if="!$store.state.comment.read" style="margin: 15px 0;">
                <el-input type="textarea" placeholder="请输入笔记内容" :rows="8" resize="none" v-model="txt" show-word-limit></el-input>
            </div>
            <div v-if="!$store.state.comment.read" style="text-align: right">
                <el-button @click="txt = ''">清空</el-button>
                <el-button @click="addComment" :loading="saveLoading" type="primary">新增</el-button>
            </div>
        </div>
    </clear-drawer>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            param: {},

            loading: false,
            list: [],
            page: 1,
            pageSize: 20,
            total: 0,
            saveLoading: false,

            txt: '',

            needLoad: false,
            title: '',
        }
    },
    watch: {
        show() {
            if (!this.show) {
                this.$store.commit('hideNote')
            }
        },
        '$store.state.note.show': {
            handler(val) {
                this.show = val

                if (val) {
                    this.param = this.$help.deepClone(this.$store.state.note)
                    delete this.param.show
                    delete this.param.read

                    const type = this.param.type

                    if (type === '0') {
                        this.title = `${this.param.comName}-笔记`
                    } else if (type === '1') {
                        this.title = `${this.param.compName}-笔记`
                    } else if (type === '2') {
                        this.title = `${this.param.compName}-${this.param.comName}-笔记`
                    }
                    this.init()
                } else {
                    this.needLoad && this.$store.state.note.cb && this.$store.state.note.cb()
                }
            },
            immediate: true,
        },
    },
    methods: {
        init() {
            this.txt = ''
            this.loading = false
            this.page = 1
            this.list = []
            this.finished = false
            this.needLoad = false

            this.getList()
        },
        radioChange() {
            this.init()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.handleCurrentChange(1)
        },
        handleCurrentChange(page) {
            this.page = page
            this.getList()
        },
        getList() {
            this.loading = true
            const param = this.$help.deepClone(this.param)
            this.$api.post('sys/sysUserNoteBook/querySysUserNoteBookPage', {
                params: param,
                page: {
                    pageNo: this.page,
                    pageSize: this.pageSize,
                },
                sort: {createdAt: 'desc'}
            })
                .then(res => {
                    if (res.success) {
                        this.total = res.data.totalCount
                        this.list = res.data.records
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        addComment() {
            if (!this.txt) {
                this.$store.commit('error', '请输入笔记内容')
                return false
            }
            const param = this.$help.deepClone(this.param)
            param.context = this.txt
            this.saveLoading = true
            this.$api.post('sys/sysUserNoteBook/insertSysUserNoteBook', param)
                .then(res => {
                    if (res.success) {
                        this.$store.commit('success', '添加成功')
                        this.getList()
                        this.txt = ''
                        this.needLoad = true
                    } else {
                        this.$store.commit('error', res.message)
                    }
                })
                .finally(() => {
                    this.saveLoading = false
                })
        },
    },
}
</script>