!
<style lang="scss" scoped>
.audio-player {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .voice_num {

    }

    .voice_icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        border: 1px solid #409eff;
        cursor: pointer;
        .icon {
            padding-right: unset !important;
        }
    }

    .voice_icon_active {
        border:unset;
        // width: 70px;
        // height: 70px;
        // background: url('../assets/img/bg_icon.png');
        // background-size: cover;
        // /* 背景图片覆盖整个元素 */
        // background-position: center center;
        // /* 背景图片居中 */
        // animation: rotateBackground 2s linear infinite;
    }
    .rotateImg{
        position: absolute;
        width: 70px;
        height: 70px;
        background: url('../assets/img/bg_icon.png');
        background-size: cover;
        /* 背景图片覆盖整个元素 */
        background-position: center center;
        /* 背景图片居中 */
        animation: rotateBackground 1.5s linear infinite;
    }

    @keyframes rotateBackground  {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}
</style>
<template>
    <div class="audio-player">
        <audio id="audioPlayer" v-if="audioUrl" :src="audioUrl" controls="controls" class="content-audio"
            style="display: block;">语音</audio>
        <!-- <div style="height:32px;width:300px;border-radius: 5px;box-sizing: border-box;display:inline-block;vertical-align:bottom"
            class="ctrlProcessWave"></div> -->

        <div class="voice_icon" :class="durationTxt > 0 ? 'voice_icon_active' : ''" @mousedown="mouseStart"
            @mouseup="mouseEnd">
            <img v-if="durationTxt > 0" class="rotateImg" src="../assets/img/bg_icon.png" alt="">
            <i class="icon icon-mic-on-full" style="color: #409EFF;font-size: 20px;"></i>
            <span class="voice_num" v-if="durationTxt > 0">{{ durationTxt }}s</span>
        </div>
        <div v-if="isPlay">
            松手发送，按Esc键取消发送
        </div>
        <div v-else>
            按住空格键开始说话，按Esc键或点击
            <el-link :underline="false" type="primary" @keydown.esc="setIsVoice" @click="setIsVoice(false)">退出</el-link>
        </div>
    </div>
</template>
<script>

//加载必须要的core，demo简化起见采用的直接加载类库，实际使用时应当采用异步按需加载
import Recorder from 'recorder-core' //注意如果未引用Recorder变量，可能编译时会被优化删除（如vue3 tree-shaking），请改成 import 'recorder-core'，或随便调用一下 Recorder.a=1 保证强引用
//需要使用到的音频格式编码引擎的js文件统统加载进来，这些引擎文件会比较大
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'
//可选的扩展
// import 'recorder-core/src/extensions/waveview'


import qiniu from "@/util/qiniu";
import store from '@/config/store';
// import recording from '@/config/recorder.js'
export default {
    props: {
        isVoice: {
            typeof: Boolean,
            default: false
        },
    },
    data() {
        return {
            voiceObj: {
                num: 60,  // 按住说话时间      
                recorder: null,
                interval: '',
                audioFileList: [],  // 上传语音列表      
                startTime: '',  // 语音开始时间      
                endTime: '',  // 语音结束
            },
            audioUrl: '',


            isPlay: false,
            type: "mp3",
            bitRate: 16,
            sampleRate: 16000,
            duration: 0,
            durationTxt: "0",
            powerLevel: 0,
            logs: []
        };
    },
    created() {
        this.Rec = Recorder;
        // this.getAudio() 
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeydown);
        window.addEventListener('keyup', this.handleKeyUp);
        // 初始化开启录音权限
        this.$nextTick(() => {
            this.recOpen()
            this.getAudio()
            // recording.get(rec => {
            //     this.voiceObj.recorder = rec
            // })
        })
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeydown);
        window.removeEventListener('keyup', this.handleKeyUp);
    },
    methods: {
        getAudio() {
            if (navigator.getUserMedia) {
                navigator.getUserMedia(
                    { audio: true } // 只启用音频        
                    , function (stream) {
                        console.log('测试111', stream);
                    }
                    , function (error) {
                        switch (error.code || error.name) {
                            case 'PERMISSION_DENIED':
                            case 'PermissionDeniedError':
                                // HZRecorder.throwError('用户拒绝提供信息。')
                                store.commit('error', '用户拒绝提供信息。')
                                break
                            case 'NOT_SUPPORTED_ERROR':
                            case 'NotSupportedError':
                                // HZRecorder.throwError('浏览器不支持硬件设备。')
                                store.commit('error', '浏览器不支持硬件设备。')
                                break
                            case 'MANDATORY_UNSATISFIED_ERROR':
                            case 'MandatoryUnsatisfiedError':
                                // HZRecorder.throwError('无法发现指定的硬件设备。')
                                store.commit('error', '法发现指定的硬件设备')
                                break
                            default:
                                // HZRecorder.throwError('无法打开麦克风。异常信息:' + (error.code || error.name))
                                break
                        }
                    })
            } else {
                // HZRecorder.throwErr('当前浏览器不支持录音功能。'); 
                store.commit('error', '当前浏览器不支持录音功能。')
                return
            }
        },
        formatMs(ms) {
            console.log('秒数', ms)
            let ss = ms % 1000; ms = (ms - ss) / 1000;
            let s = ms % 60; ms = (ms - s) / 60;

            // let m = ms % 60; ms = (ms - m) / 60;
            // let h = ms;
            // let t = (h ? h + ":" : "")
            //     + (all || h + m ? ("0" + m).substr(-2) + ":" : "")
            //     + (all || h + m + s ? ("0" + s).substr(-2) + "″" : "")
            //     + ("00" + ss).substr(-3);
            return s;
        },
        reclog(msg, color, res) {
            let obj = {
                idx: this.logs.length
                , msg: msg
                , color: color
                , res: res

                , playMsg: ""
                , down: 0
                , down64Val: ""
            }
            if (res && res.blob) {
                this.recLogLast = obj
            }
            this.logs.splice(0, 0, obj)
        },
        recOpen() {
            let This = this;
            let rec = this.rec = Recorder({
                type: This.type
                , bitRate: +This.bitRate
                , sampleRate: +This.sampleRate
                , onProcess: (buffers, powerLevel, duration, sampleRate) => {
                    This.duration = duration;
                    This.durationTxt = This.formatMs(duration);
                    This.powerLevel = powerLevel;
                    console.log('1111', buffers, sampleRate);
                    // This.wave.input(buffers[buffers.length - 1], powerLevel, sampleRate);
                }
            });
            rec.open(() => {
                // This.reclog("已打开:" + This.type + " " + This.sampleRate + "hz " + This.bitRate + "kbps", 2);
                // This.wave = Recorder.WaveView({ elem: ".ctrlProcessWave" });
            }, (msg, isUserNotAllow) => {
                console.log('打开失败', msg, isUserNotAllow);
                store.commit('error', msg)
                // This.reclog((isUserNotAllow ? "UserNotAllow，" : "") + "打开失败：" + msg, 1);
            });
        },
        setIsVoice(falg = false) {
            this.$emit('setIsVoice', falg)
        },
        //随机字符串
        generateRandomString(length = 30) {
            let result = '';
            let characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let charactersLength = characters.length;

            for (let i = 0; i < length; i++) {
                let randomIndex = Math.floor(Math.random() * charactersLength);
                result += String.fromCharCode(characters.charCodeAt(randomIndex));
            }

            return result;
        },
        // 长按说话    
        mouseStart() {
            this.recOpen()
            if (!this.rec || !Recorder.IsOpen()) {
                store.commit('error', '未打开录音')
                return;
            }
            this.rec.start();
            this.isPlay = true
            // let set = this.rec.set;
            // this.reclog("录制中：" + set.type + " " + set.sampleRate + "hz " + set.bitRate + "kbps");
        },
        // 松开时上传语音    
        mouseEnd() {
            let This = this;
            let rec = This.rec;
            if (this.isPlay && This.durationTxt > 0) {
                const time = This.$help.deepClone(This.durationTxt)
                rec.stop((blob, duration) => {
                    let localUrl = window.URL.createObjectURL(blob)
                    console.log("录音成功", blob, localUrl, "时长:" + duration + "ms");
                    let name = this.generateRandomString() + '.mp3'
                    let files = new File([blob], name)
                    let fd = new FormData()
                    fd.append('file', files);
                    fd.append('key', name)
                    qiniu.getToken().then((orr) => {
                        fd.append('token', orr.data)
                        this.uploadFile(fd, time)
                    })
                    // This.reclog("已录制:", "", {
                    //     blob: blob
                    //     , duration: duration
                    //     , durationTxt: This.formatMs(duration)
                    //     , rec: rec
                    // });
                }, () => {
                    store.commit('error', '录音失败')
                });
                This.isPlay = false
            } else {
                if (rec) {
                    rec.stop()
                    This.rec = null
                }
            }
            This.durationTxt = "0"
        },
        uploadFile(formData, time) {
            this.$api.post(qiniu.upload, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    this.$emit('uploadAudio', res.data.url, time)
                    console.log('上传mp3成功', res, time);
                })
                .catch(res => {
                    console.log('上传mp3失败', res);
                })
        },
        //键盘抬起事件
        handleKeyUp(event) {
            if (this.isVoice) {
                if (event.key === ' ') {
                    this.mouseEnd()
                }
            }
        },
        //键盘点击
        handleKeydown(event) {
            // 判断是否是某个键，例如回车键
            if (this.isVoice) {
                if (event.key === 'Escape') {
                    if (this.isPlay) {
                        this.isPlay = false
                        this.mouseEnd()
                    } else {
                        this.setIsVoice()
                    }
                }
                else if (event.key === ' ') {
                    if (!this.isPlay) {
                        this.mouseStart()
                    }
                }
            }
        },
    },
}
</script>