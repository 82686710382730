import Vue from 'vue';
import Vuex from 'vuex';
import ElementUI from 'element-ui'
import api from '@/util/api'
import variable from '@/util/variable'
import help from '@/util/help'

Vue.use(Vuex);

const config = new Vuex.Store({
    state: {
        loading: {},
        titleRefer: {},
        user: {},
        userRoot: {},
        htmlView: {
            show: false,
            content: '',
        },
        calcShow: false,
        alertInfo: {
            show: false,
            loading: false,
        },
        years: [],
        comment: {
            type: '0',
            comCode: '',
            comName: '',
            compCode: '',
            compName: '',
            read: false,
            show: false,
        },
        note: {
            type: '0',
            comCode: '',
            comName: '',
            compCode: '',
            compName: '',
            read: false,
            show: false,
        },

        // 分类信息
        kind: [],

        unReadCount: 0,
        //药品对比数据
        contrastList: [],
        //成分适应症组合对比数据
        combinationObj: {
            show: false,
            title: '成分',
            radioArr:['成分','适应症'],
            componentData: [],      //成分
            indicationData: [],     //适应症
            list:[],                //表格数据
        },
        isRead:false,  //判断是否有未读
        isReadObj:{
            helpNoticeNumber:0,
            sysSendNumber : 0,
        },  
        contrastShow:false,
    },
    mutations: {
        setCombinationObjShow(state, falg){
            state.combinationObj.show = falg
        },
        setCombinationObj(state, {name,str,title}) {
            console.log('成分加入',state, name,str,title);
            state.combinationObj.title = title
            if(state.combinationObj[str].includes(name)){
                return config.commit('error', `该${title}已添加`)
            }else{
                state.combinationObj[str].push(name)
            }
        },
        setContrastShow(state, falg){
            state.contrastShow = falg
        },
        clickContrast(state, row) {
            if (state.contrastList.findIndex(item => item.comCode === row.comCode) != -1) return config.commit('error', '该药品已添加')
            state.contrastList.push(row)
            config.commit('success', `已添加${state.contrastList.length}条`)
        },
        setUnReadCount(state, num) {
            state.unReadCount = num
        },
        clearUser(state) {
            state.user = {}
        },
        setUserImg(state, img) {
            state.user.picUrl = img
        },
        // region 加载
        loading(state, target) {
            target = target ? target : 'body';
            state.loading = ElementUI.Loading.service({
                lock: true,
                target: target
            })
        },
        loaded(state) {
            state.loading.close && state.loading.close();
        },
        // endregion

        setTitleRefer(state, param) {
            state.titleRefer = param
        },

        showHtml(state, content) {
            state.htmlView.show = !!content
            state.htmlView.content = content || ''
        },
        setYears(state, years) {
            let arr = []
            let quarter = []

            let index = 2
            let lastYear = 2016
            let lastYearModel = {}
            const yearMap = {}
            const quarterMap = {}
            let lastQuarterModel = {}
            while (true) {
                const val = years[index.toString()]
                if (val) {
                    yearMap[index.toString()] = val.year
                    if (val.quarter.length === 4) {
                        arr.push({
                            key: index,
                            keyYear: val.year,
                            value: val.year
                        })
                        if (val.year > lastYear) {
                            lastYear = val.year
                            lastYearModel = {
                                key: index,
                                value: val.year
                            }
                        }
                    }

                    val.quarter.forEach(item => {
                        quarter.push({
                            key: `${index}q${item}`,
                            value: `${val.year}Q${item}`,
                            newValue: `${val.year}q${item}`
                        })
                        lastQuarterModel = {
                            key: `${index}q${item}`,
                            value: `${val.year}Q${item}`
                        }
                        quarterMap[`${index}q${item}`] = `${val.year}Q${item}`
                    })
                    index++
                } else {
                    break
                }
            }
            arr = arr.sort((a, b) => a.key - b.key)

            variable.yearMap = yearMap
            variable.years = help.deepClone(arr)
            variable.yearsLength = arr.length
            variable.lastYear = lastYearModel
            variable.quarter = quarter
            variable.quarterMap = quarterMap
            variable.lastQuarter = lastQuarterModel

            state.years = arr
        },

        calcShow(state, flag) {
            state.calcShow = flag
        },

        // 评论页面
        showComment(state, param) {
            if (!help.token.get()) {
                help.goLogin()
                return false
            }
            state.comment.type = param.type || '0'
            state.comment.comCode = param.comCode
            state.comment.comName = param.comName
            state.comment.compCode = param.compCode
            state.comment.compName = param.compName
            state.comment.show = true
            state.comment.read = !!param.read
            state.comment.cb = param.cb
        },
        hideComment(state) {
            state.comment.show = false
        },

        // 笔记
        showNote(state, param) {
            if (!help.token.get()) {
                help.goLogin()
                return false
            }

            state.note.type = param.type || '0'
            state.note.comCode = param.comCode
            state.note.comName = param.comName
            state.note.compCode = param.compCode
            state.note.compName = param.compName
            state.note.show = true
            state.note.read = !!param.read
            state.note.cb = param.cb
        },
        hideNote(state) {
            state.note.show = false
        },

        // region 弹窗
        error(state, msg) {
            ElementUI.Message({
                showClose: true,
                dangerouslyUseHTMLString: true,
                message: msg,
                type: 'error',
                duration: 5000,
            });
        },
        success(state, msg) {
            ElementUI.Message({
                showClose: true,
                message: msg,
                type: 'success'
            });
        },
        info(state, msg) {
            ElementUI.Message({
                showClose: true,
                message: msg,
            });
        },
        alert(state, option = {}) {
            if (typeof option === 'string') {
                option = {
                    content: option
                }
            }
            let defaultOption = {
                show: true,
                title: '提示',
                content: '请先选择有效记录，再执行该操作。',
                loading: false,
                hideCancel: true,
                callback: () => {
                    state.alertInfo.loading = false
                    state.alertInfo.show = false
                },
            }
            state.alertInfo = help.joinObj(defaultOption, option)
        },
        confirm(state, option = {}) {
            let defaultOption = {
                show: true,
                title: '提示',
                content: '',
                loading: false,
                callback: () => {
                },
            }
            state.alertInfo = help.joinObj(defaultOption, option)
        },

        // endregion
    },
    actions: {
        getUser({ state }, flag) {
            if (state.user.id && !flag) {
                return new Promise(res => {
                    res(state.user)
                })
            } else {
                return api.get('sys/sysUser/me')
                    .then(res => {
                        if (res.code === variable.SUCCESS_CODE) {
                            // if (res.data.roleId === variable.member.temp) {
                            //     res.data.user.isTemp = true
                            // } else if (res.data.roleId === variable.member.vip) {
                            //     res.data.user.isVip = true
                            // } else if (res.data.roleId === variable.member.visit) {
                            //     res.data.user.roleName = '游客'
                            // }
                            // state.user = res.data.user
                            // state.userRoot = res.data

                            state.user = res.data
                            return res.data
                        } else {
                            return ''
                        }
                    })
            }
        },

        getKind({ state }) {
            if (state.kind.length > 0) {
                return new Promise(res => {
                    res(state.kind)
                })
            } else {
                return api.post('help/helpCenterClassify/queryHelpCenterClassifyTreeList', {
                    sort: { orderIndex: 'asc' },

                })
                    .then(res => {
                        state.kind = res.data
                        return state.kind
                    })
            }
        },
        getUnread({ state }){
            api.post('help/HelpNotice/isRead')
                .then(res => {
                    state.isRead = res.data
                    state.isReadObj = res.dataMap
                })
                .finally(() => {

                })
        },
    }
})

export default config