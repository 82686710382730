<style lang="scss">
@import "~@/assets/css/var";
.my-notice {
    width: 1200px;
    margin: 0 auto;
    &__desc {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #9E9E9E;
    }

    .panel {
        position: relative;
        margin-top: 12px;
        padding: 35px 45px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    }
    &__title {
        position: relative;
        color: $main;
        font-size: 22px;
        font-weight: 500;
        line-height: 40px;
        i {
            margin-right: 10px;
        }
    }
    &__operate {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    &__panel {
        margin: 10px 10px;
        border-top: 1px solid $border;
        padding: 25px;

        &-item {
            position: relative;
            &:before {
                position: absolute;
                top: 8px;
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #4C4C4C;
            }
            .head {
                display: flex;
                align-items: center;
                padding-left: 10px;
                margin-top: 10px;
                font-size: 14px;
                padding-bottom: 8px;

                .el-tag {
                    margin-left: 10px;
                }
            }
            .title {
                flex-shrink: 0;
                font-weight: 600;
                line-height: 23px;
            }
            .desc {
                margin-left: 12px;
                font-weight: normal;
                line-height: 14px;
                flex: 1;
            }
            .line {
                border-bottom: 1px solid $border;

                &.open {
                    margin-right: 95px;
                }
            }
            .go {
                margin-left: 5px;
                color: $main;
            }
            .body {
                font-size: 14px;
                white-space: pre-wrap;
                padding-bottom: 8px;
                text-indent: 28px;
            }
            .close {
                font-size: 14px;
                padding-top: 10px;
                text-align: right;
                color: $main;
                padding-right: 50px;
            }
        }

        .page {
            padding-top: 15px;
            text-align: center;
        }
    }
}
</style>
<template>
    <div class="index-page">
        <img src="@/assets/img/banner.png" alt="banner">

        <div class="my-notice">
            <p class="my-notice__desc">首页 - 公告-更多</p>

            <div class="panel">
                <div class="my-notice__title">
                    <i class="el-icon-s-platform"></i>公告通知
                </div>

                <div class="my-notice__panel">
                    <div class="my-notice__panel-item" v-for="item in list" :key="item.id">
                        <div class="head">
                            <span class="title">{{item.updatedAt.split(' ')[0]}}</span>
                            <span v-show="!item.open" class="desc ellipsis" :title="item.content">{{item.content}}</span>
                            <span class="go clickable" v-show="!item.open" @click="item.open = true">展开</span>
                        </div>
                        <div class="body" v-show="item.open">{{item.content}}</div>
                        <div class="line" :class="{open: item.open}"></div>
                        <div class="close" v-show="item.open">
                            <span class="close clickable" @click="item.open = false">收起</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'my-notice',
            pageName: '公告',

            list: []
        }
    },
    created() {
        this.$help.socket.send(this)

        // 加载前十条
        this.$api.post('help/HelpNotice/queryHelpNoticeDesktopPage', {
            sort: {
                updatedAt: 'desc'
            },
            page: {pageNo: 1, pageSize: 10},
        })
            .then(res => {
                const openId = this.$route.query.open
                if (res.success) {
                    this.list = res.data.records.map(item => {
                        item.open = openId ? (item.id === openId) : false
                        return item
                    }) || []
                }
            })
    },
}
</script>