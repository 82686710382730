<template>
    <div class="index-page help-index help-list requirement">
        <div class="help-index__banner">
            <img src="@/assets/img/help-banner.png" alt="banner">
            <help-search></help-search>
        </div>

        <div class="my-notice" style="margin-top: 15px;">
            <p class="my-notice__desc">帮助中心 - 使用教程-更多</p>
        </div>

        <div class="help-list__panel">
            <div class="help-list__left" v-loading="loading">
                <div class="requirement-left" :class="{active: detail.id === item.id}" @click="kindChange(item)"
                     v-for="item in list" :key="item.id">
                    <span>{{ item.question }}</span>
                </div>
            </div>
            <div class="help-list__right">
                <div style="text-align: center;font-size: 16px" v-if="list.length === 0 && !loading">搜索不到数据</div>
                <div>
                    <p class="title">
                        {{ detail.question }}
                        <span>{{detail.updatedAt}}</span>
                    </p>
                    <div class="content" v-html="detail.answer"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HelpSearch from "./HelpSearch.vue";

export default {
    components: {HelpSearch},
    data() {
        return {
            loading: false,
            list: [],

            detail: {},
        }
    },
    watch: {
        '$route.query.keyword'() {
            this.query()
        }
    },
    created() {
        this.query()
    },
    methods: {
        query() {
            this.list = []
            this.detail = {}
            this.loading = true
            this.$api.post('help/helpCenter/queryHelpCenterPage', {
                page: {
                    pageNo: 1,
                    pageSize: 10,
                },
                conditionList: [{
                    name: 'question',
                    op: 'like',
                    value: this.$route.query.keyword
                }]
            })
                .then(res => {
                    this.list = res.data.records
                    if (this.list.length > 0) {
                        this.detail = this.list[0]
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        kindChange(item) {
            this.detail = item
        },
    }
}
</script>