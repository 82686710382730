<template>
    <el-drawer class="my-drawer" :visible.sync="show" v-loading="loading" :size="max ? '100%' : size" :show-close="false" :before-close="close">
        <template slot="title">
            <div class="my-drawer__title">{{title}}</div>
            <span>
                <i v-if="!hideMax" class="el-icon-full-screen" @click="max = !max"></i>
                <i class="el-icon-close" @click="close"></i>
            </span>
        </template>

        <slot></slot>
    </el-drawer>
</template>

<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: false
            },
            hideMax: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: '70%'
            },
            title: {},
        },
        data() {
            return {
                max: false,
            }
        },
        methods: {
            close() {
                this.$emit('update:show', false);
                return false;
            },
        }
    }
</script>