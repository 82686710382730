<template>
    <el-autocomplete v-model="model" :fetch-suggestions="querySearch" prefix-icon="el-icon-search" @keyup.enter.native="handleSelect"
                     placeholder="请输入搜索内容" @select="handleSelect" size="small">
        <template #append>
            <div @click="handleSelect">搜索</div>
        </template>
    </el-autocomplete>
</template>

<script>
export default {
    data() {
        return {
            model: '',
        }
    },
    methods: {
        querySearch(txt, cb) {
            this.$api.post('help/helpCenter/queryHelpCenterPage', {
                page: {
                    pageNo: 1,
                    pageSize: 10
                },
                conditionList: [{
                    // name: 'answer',
                    name: 'question',
                    op: 'like',
                    value: txt
                }]
            })
                .then(res => {
                    cb(res.data.records.map(ele => {
                        return {
                            value: ele.question,
                            model: ele
                        }
                    }))
                })
        },
        handleSelect() {
            if (this.model) {
                this.$router.push({
                    path: '/help-result',
                    query: {
                        keyword: this.model
                    }
                })
            } else {
                this.$message.error('请输入搜索内容')
            }
        },
    }
}
</script>