<!-- 多重便捷筛选 -->
<style></style>
<template>
    <div class="flex" style="display: inline; margin-left: 20px;" v-if="screenList && screenList.length > 0">
        <div v-for="(col, index) in screenList" :key="index" style="margin-right: 5px;">
            <el-button type="primary" @click="col.event(col.num)">{{ col.value }}</el-button>
        </div>
        <div>
            <el-date-picker v-model="time" v-show="isFinite"
            style="margin-right: 5px;width: 220px;" start-placeholder="开始日期" end-placeholder="结束日期"
            type="daterange" value-format="yyyy-MM-dd" @change="clickDaterange"></el-date-picker>
        </div>
        
    </div>
</template>

<script>
const currentDate = new Date()
export default {
    props: {
        isFinite:{
            default: Boolean,
            typeof: true
        },
        screenName: {
            default: String,
            typeof: ''
        },
        newScreenList: {
            typeof: Array,
            default: () => []
        }
    },
    data() {
        return {
            screenList: [
                {
                    key: 'week',
                    value: '近一周',
                    event: this.setWeek,
                    num: 0,
                },
                {
                    key: 'threeMonths',
                    value: '三个月',
                    event: this.setTimeNum,
                    num: 3,
                },
                {
                    key: 'halfYear',
                    value: '近半年',
                    event: this.setTimeNum,
                    num: 6,
                },
                {
                    key: 'allData',
                    value: '全部',
                    event: this.allData,
                    num: 0,
                },
            ],
            condList: [],
            time: '',
        }
    },
    watch: {
        newScreenList: {
            handler(newVal) {
                if (newVal && newVal.length > 0) {
                    const arr = this.$help.deepClone(newVal)
                    arr.map(item => {
                        if (item.key === 'allData') {
                            this.$set(item, 'event', this.allData)
                        } else if (item.num > 0) {
                            this.$set(item, 'event', this.setTimeNum)
                        }
                        return item
                    })
                    this.screenList = arr
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        clickDaterange(val) {
            if (val && val.length != 0) {
                this.setSeachTime(val[0], val[1])
            } else {
                this.setSeachTime(null, null, false)
            }

        },
        //近一周
        setWeek() {
            //计算一周时间
            let oneWeekAgo = currentDate.getTime() - 7 * 24 * 60 * 60 * 1000;
            let oneWeekAgoDate = new Date(oneWeekAgo);
            this.getDate(oneWeekAgoDate)
        },
        newTime(num) {
            const time = new Date(currentDate.getFullYear(), currentDate.getMonth() - num, currentDate.getDate());
            return time
        },
        setTimeNum(num) {
            this.getDate(this.newTime(num))
        },
        //转换格式
        convertFormat(val) {
            let yy = val.getFullYear();
            let mm = String(val.getMonth() + 1).padStart(2, '0');
            let dd = String(val.getDate()).padStart(2, '0');
            return yy + '-' + mm + '-' + dd;
        },
        //获取当前时间/之前的时间
        getDate(time) {
            let formattedDate = this.convertFormat(time);
            let date = this.convertFormat(currentDate)
            this.setSeachTime(formattedDate, date)
        },
        //筛选
        setSeachTime(geTime, leTime, fleg = true) {
            let _geTime = geTime + ' 00.00.00'
            let _leTime = leTime + ' 23.59.59'
            this.condList = [
                {
                    name: this.screenName,
                    op: 'ge',
                    value: fleg ? _geTime : ''
                },
                {
                    name: this.screenName,
                    op: 'le',
                    value: fleg ? _leTime : ''
                },
            ]
            this.$emit('searchTime', this.condList)
        },
        allData() {
            this.setSeachTime('', '', false)
        },
    },
}
</script>