<template>
    <div class="ts-upload" style="display: flex;">
        <el-upload ref="upload" :action="action" :auto-upload="false" :multiple="false" :show-file-list="false"
            :data="postData" :on-change="handleChange" :on-success="success" :on-error="failed"
            :before-upload="beforeUpload">
            <el-button class="btn-blue" :loading="fileLoading">
                <template v-if="fileLoading">上传中...</template>
                <template v-else-if="file">更换文件</template>
                <template v-else>选择文件</template>
            </el-button>
        </el-upload>
        <el-link style="margin-left: 10px;" :underline="false" v-if="file" type="primary" :href="file"
            target="_blank">查看文件</el-link>
    </div>
</template>

<script>
import qiniu from '@/util/qiniu'
export default {
    props: {
        value: {},
        typeFile:{
            type: String,
            default:''
        },
    },
    // watch: {
    //     value(newVal) {
    //         this.file = newVal
    //     },
    // },
    data() {
        return {
            fileLoading: false,
            action: qiniu.upload,
            postData: {
                token: '',
                key: '',
            },
            file: '',
        }
    },
    created() {
        if (this.value) {
            this.file = this.value
        }
    },
    methods: {
        //判断上传的类型
        getSendType(file) {
            const index = file.lastIndexOf(".");
            const fileType = file.substr(index + 1);
            const typeList = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg']
            return typeList.includes(fileType)
        },
        handleChange(file) {
            if (this.read || this.fileLoading) {
                return
            }
            const arr = this.getSendType(file.name)
            if(this.typeFile === 'img' && !arr){
                this.$refs.upload.clearFiles()
                return  this.$store.commit('error', '请上传图片类型')
            } 
            if(this.typeFile === 'file' && arr){
                this.$refs.upload.clearFiles()
                return this.$store.commit('error', '请上传文件类型')
            } 
            const maxSize = 50 * 1024 * 1024; // 50MB
            if (file.size > maxSize) {
                this.$refs.upload.clearFiles()
                return this.$store.commit('error', `大小超过了50MB的限制`)
            }
            console.log('选择的文件',file);
            this.$emit('begin')
            this.fileLoading = true
            qiniu.getToken().then(res => {
                if (res.success) {
                    this.postData.token = res.data
                    this.$refs.upload.submit();
                }
            })
        },
        beforeUpload(file) {
            const name = file.name
            const splitStart = name.lastIndexOf('.')
            this.postData.key = `${name.substr(0, splitStart)}-${new Date().Format('yyyyMMddhhmmss')}${name.substr(splitStart)}`
        },
        success(obj) {
            console.log('点击发送',obj)
            this.file = obj.data.url
            if(this.typeFile === 'img'){
                this.$emit('changeImg', this.file)
            }else if(this.typeFile === 'file'){
                this.$emit('changeFile', this.file)
            }else{
                this.$emit('change', this.file )
            }
            
            setTimeout(() => {
                this.fileLoading = false
            }, 300)
        },
        failed(obj) {
            console.log(obj)
            setTimeout(() => {
                this.fileLoading = false
            }, 300)
        },
    }
}
</script>