<!-- 成分/适应症 组合对比弹窗 -->
<template>
    <div class="custom-dialog" ref="CombinationDialog" v-drag-div>
        <div class="dialog-header dragging">
            <div>
                <el-radio-group v-model="title" size="mini">
                    <el-radio-button v-for="(item, index) in $store.state.combinationObj.radioArr" :key="index"
                        :label="item"></el-radio-button>
                </el-radio-group>
                组合对比
            </div>
            <i class="el-icon-close dragging" style="cursor: pointer !important;font-size: 18px;"
                @click.stop="closePay"></i>
        </div>
        <div class="dialog-content">
            <div class="comText_box dis_flex">
                <div class="comText_box_left">
                    <div class="dis_flex">
                        <span class="font_bold">{{ title }}</span>
                        <p v-show="leftList.length > 0">
                            （已选择 <span class="font_bold" style="color: #6684EC;">{{ leftList.length }}</span>个）
                        </p>
                    </div>
                    <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="leftText" clearable
                        @input="leftInput"></el-input>
                    <div class="comText_box_left_list">
                        <div class="comText_com" v-for="(item, index) in leftList" :key="index">
                            {{ item }}
                            <i class="el-icon-close" style="color: red;" @click="deleteLeft(item)"></i>
                        </div>
                        <div v-if="leftList.length === 0" class="dis_flex" style="justify-content: center;">暂无数据！</div>
                    </div>
                </div>
                <div class="comText_box_right">
                    <div class="dis_flex" style="justify-content: space-between;">
                        <div class="dis_flex">
                            <span class="font_bold">药品</span>
                            <p v-show="multipleSelection.length > 0">
                                （已选择 <span class="font_bold" style="color: #6684EC;">{{ multipleSelection.length
                                    }}</span>个药品）
                            </p>
                        </div>
                        <div v-if="isTable">
                            <el-select v-model="drug.model" value-key="comCode" :loading="drug.loading"
                                placeholder="请选择药品" reserve-keyword filterable remote :remote-method="drugSearch"
                                collapse-tags clearable multiple style="width: 200px;">
                                <el-option v-for="item in drug.list" :key="item.comCode" :label="item.comName"
                                    :value="item"></el-option>
                            </el-select>
                            <el-button style="margin-left: 10px;" type="primary" @click="clickSearch"
                                :disabled="drug.loading">筛选</el-button>
                        </div>
                    </div>
                    <div class="comText_box_right_table">
                        <ts-table v-if="isTable" ref="table" class="flex-item" :cols="cols" :isContrastIcon="false"
                            :data="list" :page-param="pageParam" :loading="loading" @handleSort="sort"
                            :error-code="error" style="height: 395px;" name="CombinationDialog" hide-table-head multiple
                            @handleRowDbClickChange="handleRowDbClickChange" full hide-detail
                            @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
                            @selectionChange="selectionChange">
                        </ts-table>
                    </div>
                </div>
            </div>
            <div class="comText_btn"><el-button type="primary" :disabled="multipleSelection.length === 0"
                    @click="goContrast">跳转药品对比</el-button></div>
        </div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
export default {
    mixins: [myMixin],
    props:{
        newTitle:{
            type: String,
            default: ''
        },
    },
    data() {
        return {
            pageApi: '',
            str: '',

            isDragging: false,
            dragStartX: 0,
            dragStartY: 0,
            stop: true,
            isTable: true,

            leftList: [],
            leftParam: [],
            leftText: '',
            title: this.$store.state.combinationObj.title,

            multipleSelection: [],

            drug: {
                model: {},
                list: [],
                loading: false,
            },
            defaultConditionList: [],

            cols: this.$help.generalCols([
                this.$variable.indexColumn.drug(),
                {
                    fieldName: "baseDrug",
                    name: "基药",
                    listOrderView: '是',
                    listQueryView: "是",
                    listQueryModel: 1,
                    width: 60,
                    link: this.goBase,
                    listClass: 'tc',
                },
                {
                    fieldName: "medicalInsurance",
                    name: "医保",
                    listOrderView: '是',
                    listQueryView: "是",
                    listQueryModel: 1,
                    width: 60,
                    link: this.goHealth,
                    listClass: 'tc',
                },

                {
                    fieldName: "compNumber",
                    name: `企业\\n数量`,
                    listOrderView: '是',
                    width: 60,
                    link: this.goCompNum,
                    listClass: 'tc',
                },
                {
                    fieldName: "componentNumber",
                    name: `成分\\n数量`,
                    listOrderView: '是',
                    width: 60,
                    // link: this.goChina,
                    listClass: 'tc',
                },
                {
                    fieldName: 'saleAmount',
                    name: `销售额\\n（万元）`,
                    width: 70,
                    listQueryView: '否',
                    listQueryModel: 1,
                    listOrderView: '是',
                },
            ]),
        };
    },
    watch: {
        title: {
            handler(val) {
                this.isTable = false
                const cols = this.$help.deepClone(this.cols)
                cols.map(item => {
                    if (item.fieldName === 'componentNumber' || item.fieldName === 'indicationNumber') {
                        item.fieldName = val === '成分' ? 'componentNumber' : 'indicationNumber'
                        item.name = val === '成分' ? '成分\\n数量' : '适应症\\n数量'
                    }
                })
                this.cols = cols
                this.str = val === '成分' ? 'componentData' : 'indicationData'
                this.leftList = this.$store.state.combinationObj[this.str]
                this.leftText = ''
                this.pageApi = val === '成分' ? 'contrast/drugComponentContrast/queryDrugComponentContrastPage' : 'contrast/drugIndicationContrast/queryDrugIndicationContrastPage'
                this.drug = {
                    model: {},
                    list: [],
                    loading: false,
                }
                this.defaultConditionList = []
                this.pageParam.total = 0
                this.drugSearch()
                setTimeout(() => {
                    this.isTable = true
                }, 200)
            },
            immediate: true,
            deep: true,
        },
        leftList: {
            handler(val) {
                this.leftParam = []
                if (val && val.length > 0) {
                    const _str = this.title === '成分' ? 'components' : 'indications'
                    val.forEach(item => {
                        this.leftParam.push({
                            name: _str,
                            op: 'like',
                            value: item,
                        })
                    })
                }
                this.clickSearch()
            },
            immediate: true,
            deep: true,
        },
        newTitle:{
            handler(val) {
                if(val != this.title){
                    this.title = val;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        // paramFunc(query) {
        //     console.log('列表参数', query);
        //     return query
        // },
        convertData(val) {
            if (val === 0 || val === '0') {
                return ''
            }
            return val
        },
        listComputed(list) {
            return list.map(item => {
                item.compNumber = this.convertData(item.compNumber)
                return item
            })
        },
        deleteLeft(text) {
            const arr = this.$store.state.combinationObj[this.str]
            const index = arr.indexOf(text)
            arr.splice(index, 1)
            this.leftInput(this.leftText)
        },
        leftInput(value) {
            let list = this.$help.deepClone(this.$store.state.combinationObj[this.str]) || []
            if (list && list.length > 0) {
                list = list.filter(item => { return item.includes(value) })
            }
            this.leftList = list
            this.$store.state.combinationObj[this.str] = this.leftList
            this.$store.state.combinationObj.title = this.title
        },
        selectionChange(val) {
            this.multipleSelection = val
        },
        drugSearch(keyword = '') {
            this.drug.loading = true
            this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {
                keyword: keyword,
            })
                .then(res => {
                    this.$set(this.drug, 'list', res.data)
                })
                .finally(() => {
                    this.drug.loading = false
                })
        },
        clickSearch() {
            this.defaultConditionList = []
            if (this.drug.model && this.drug.model.length > 0) {
                let obj = {
                    name: "comName",
                    op: "in",
                    value: []
                }
                this.drug.model.forEach(item => {
                    obj.value.push(item.comName)
                });
                this.defaultConditionList.push(obj)
            }
            if (this.leftParam && this.leftParam.length > 0) {
                this.defaultConditionList = this.defaultConditionList.concat(this.leftParam)
            }
            this.init()
            this.multipleSelection = []
        },
        goContrast() {
            if (this.multipleSelection && this.multipleSelection.length > 20) {
                this.$store.commit('error', '最多只能选择20个药品进行对比！')
                return
            }
            const { href } = this.$router.resolve({
                path: '/drug-compare',
            });
            localStorage.setItem("all_contrastList", JSON.stringify(this.multipleSelection));
            window.open(href, '_blank');
        },
        closePay() {
            this.$store.commit('setCombinationObjShow', false)
        },

        goHealth(data) {
            const { href } = this.$router.resolve({
                path: '/store/du_health_insurance_directories',
                query: {
                    comName: data.comName,
                    comCode: data.comCode,
                }
            });
            window.open(href, "_blank");
        },
        goBase(data) {
            const { href } = this.$router.resolve({
                path: '/store/du_essential_drugs',
                query: {
                    comName: data.comName,
                    comCode: data.comCode,
                }
            });
            window.open(href, "_blank");
        },
        goCompNum(data){
            const { href } = this.$router.resolve({
                path:'/store/dr_domestic_drugs',
                query: {
                    comName: data.comName,
                    comCode: data.comCode,
                    CompName:  undefined,
                }
            });
            window.open(href, "_blank");
        },
    },
};
</script>

<style scoped lang="scss">
.custom-dialog {
    position: fixed;
    top: 20%;
    left: 20%;
    z-index: 1000;
    width: 900px;
    height: 500px;
    border-radius: 5px;
    // background: #F7F7F7 !important;
    color: #303133 !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
    padding: 0 10px 10px;
    box-sizing: border-box;
    background: radial-gradient(49% 49% at 105% -11%, #cdd8fb 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(57% 57% at -13% -22%, #c4d2f3 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #E3EEFC 0%, #FEFFFF 33%, #FFFFFF 83%);
}

.dialog-header {
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dialog-wrapper {
    position: absolute;
    user-select: none;
}

.dis_flex {
    display: flex;
}

.dialog-content {
    width: 100%;
    height: 450px;

    .font_bold {
        font-weight: bold;
    }

    .comText_box {
        width: 100%;
        height: 425px;

        &_left {
            width: 20%;
            padding-right: 10px;
            box-sizing: border-box;

            &_list {
                margin-top: 5px;
                height: calc(100% - 55px);
                overflow-y: auto;
            }

            .comText_com {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                padding: 4px 15px 4px 5px;

                i {
                    font-size: 14px;
                    cursor: pointer;
                    color: red;
                }
            }
        }

        &_right {
            width: 80%;

            &_table {
                width: 100%;
                height: calc(100% - 30px);

                .cell {
                    white-space: break-spaces;
                }
            }
        }
    }

    .comText_btn {
        float: right;
    }
}

.dragging {
    cursor: move;
}
</style>