<style lang="scss">
@import "~@/assets/css/var";
.requirement {
    .help-list__left {
        padding: 20px;
    }
    .help-list__right {
        .status {
            background: #F11C1C;
            margin-left: 10px;
            border-radius: 3px;
            color: #fff;
            width: 60px;
            text-align: center;
            height: 24px;
            line-height: 24px;

            &.success {
                background: $main;
            }
        }
        .txt {
            i {
                color: #afafaf;
                font-size: 10px;
            }
        }
    }
    &-left {
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 0 20px;
        line-height: 30px;
        font-size: 15px;
        cursor: pointer;
        margin-bottom: 5px;

        &.active, &:hover {
            color: #fff;
            background: $main;
            i {
                color: #fff;
            }
        }
        i {
            color: $main;
            font-size: 18px;
        }
        .icon {}
        span {
            flex: 1;
            text-align: center;
        }
        .plus {}
    }
}
</style>


<template>
    <div class="index-page help-index help-list requirement">
        <img src="@/assets/img/banner.png" alt="banner">

        <div class="help-list__panel">
            <div class="help-list__left" v-loading="kindLoading">
                <div class="requirement-left" :class="{active: active.dataCode === item.dataCode}" @click="kindChange(item)"
                     v-for="item in kind" :key="item.id">
                    <i class="icon" :class="iconMap[item.dataCode]"></i>
                    <span>{{ item.dataName }}</span>
                    <i class="el-icon-circle-plus-outline plus" @click="goPage(item)"></i>
                </div>
            </div>
            <div class="help-list__right" v-loading="loading">
                <p class="title">{{active.dataName}}</p>
                <div class="list">
                    <div class="item" v-for="item in list" :key="item.id">
                        <span class="txt">{{ item.requirementContent }}<i>x{{item.requirementNumber}}</i></span>
                        <span class="time">{{ item.createdAt.split(' ')[0] }}</span>
                        <span class="status" :class="{success: item.requirementStatus !== 'UNCOMPLETED'}">{{ item.requirementStatusName }}</span>
                    </div>
                </div>
                <div class="page">
                    <el-pagination layout="prev, pager, next, total" :current-page.sync="page.pageNo" :total="total" :page-size="page.pageSize"
                                   @current-change="pageChange"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'requirement',
            pageName: '需求列表',

            iconMap: {
                PRODUCT_ANALYSIS: 'el-icon-data-analysis',
                PRODUCT_NEWS: 'el-icon-tickets',
                COMPETITIVE_PRODUCTS: 'el-icon-document-copy',
                CLINICAL_LITERATURE: 'el-icon-document',
                ENTERPRISE_NEWS: 'el-icon-document-remove',
                CLINICAL_PATHWAY: 'el-icon-c-scale-to-original',
                INSTRUCTION_BOOK: 'el-icon-reading',
                CLINICAL_ZN: 'el-icon-cpu',
            },
            pageMap: {
                PRODUCT_ANALYSIS: '6',
                PRODUCT_NEWS: '4',
                COMPETITIVE_PRODUCTS: '7',
                CLINICAL_LITERATURE: '1',
                ENTERPRISE_NEWS: '5',
                CLINICAL_PATHWAY: '3',
                INSTRUCTION_BOOK: '0',
                CLINICAL_ZN: '2',
            },

            kindLoading: false,
            kind: [],

            active : {},


            list: [],
            loading: false,

            total: 0,
            page: {
                pageNo: 1,
                pageSize: 10,
            },
        }
    },
    created() {
        this.$help.socket.send(this)
        // 获取所有类型
        this.kindLoading = true
        this.$api.post('sys/sysDictItem/querySysDictItemList', {params: {
                headId: 'REQUIREMENT_TYPE'
            }})
            .then(res => {
                this.kind = res.data
                this.kindChange(res.data[0])
            })
            .finally(() => {
                this.kindLoading = false
            })
    },
    methods: {
        kindChange(item) {
            this.active = item
            this.init()
        },

        init() {
            this.page.pageNo = 1
            this.list = []
            this.total = 0
            this.getList()
        },
        getList() {
            this.loading = true
            this.$api.post('sys/sysRequirementPublishing/querySysRequirementPublishingPage', {
                page: this.page,
                params: {
                    requirementType: this.active.dataCode,
                }
            })
                .then(res => {
                    this.list = res.data.records
                    this.total = res.data.totalCount
                })
                .finally(() => {
                    this.loading = false
                })
        },
        pageChange(val) {
            this.page.pageNo = val
            this.getList()
        },

        goPage(item) {
            this.$router.push({
                path: '/make/',
                query: {
                    type: this.pageMap[item.dataCode],
                }
            })
        },
    }
}
</script>