import axios from 'axios';
import variable from '@/util/variable';
import help from '@/util/help';
import store from '@/config/store';

let flag = false

const host = '/taoshu-data/pc/'
let myAxios = axios.create({
    baseURL: host,
    responseType: 'json',
    withCredentials: false,
    timeout: 1800000,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Accept": "application/json",
    },
})

myAxios.interceptors.request.use(function (config) {
    config.headers['H-TOKEN'] = 'blue_data'
    if (help.token.get()) {
        config.headers['Authorization'] = help.token.get() || ''
    }
    config.headers['Cache-Control'] = 'no-cache'
    return config;
}, function (error) {
    return Promise.reject(error);
})

function doError(res, reject) {
    if (res && res.data) {
        let arr =  ['#/message','#/note','#/star','#/members',]

        if (variable.tokenInvalid.includes(res.data.code)) {
            help.token.remove()       
            help.removeClientId()
            if(arr.includes(window.location.hash) && res.data.code === 'A0001'){
                window.location.replace('#/')
            }else{
                window.location.reload()
            }
            help.deleteCookie('myCookie')
        }else if(res.code === 'S0001'){
            store.commit('error', res.message)
        }else if (res.data.code === 'S0001'){
            store.commit('error', res.data.message)
        }else {
            reject(res)
            // !isBubbling && store.commit('error', res.data.msg)
        }
    }
    // else {
    //     !isBubbling && store.commit('error', '服务器内部错误')
    // }

    reject(res.data)
}

function requestFunc(func, url, params, headers = {}, isBubbling = false) {
    return new Promise((resolve, reject) => {
        let param1 = {},
            param2 = {};
        if (func === 'get' || func === 'delete') {
            param1 = {params, headers};
        } else {
            param1 = params;
            param2 = {headers}
        }
        myAxios[func](url, param1, param2)
            .then(res => {
                resolve(res.data)
                if (res.data.code !== variable.successCode) {
                    doError(res, reject, isBubbling)
                }
                
            })
            .catch(res => {
                // 如果需要验证码
                if (res.message === 'Request failed with status code 509') {
                    if (!flag) {
                        flag = true
                        let html = res.response.data
                        let verifyWindow = window.open("","_blank","height=400,width=560,left=100,top=100")
                        verifyWindow.document.write(html)
                        verifyWindow.document.getElementById("baseUrl").value = 'taoshu-data'
                    }
                } else {
                    doError({
                        data: {
                            status: res.response.data.status_code,
                            msg: res.response.data.message,
                        }
                    }, reject, isBubbling)
                }
            })
    })
}

export default {
    service: myAxios,
    axios,
    get(url, params, headers = {}, isBubbling = false) {
        return requestFunc('get', url, params, headers, isBubbling)
    },
    getSelf(url, params, headers = {}) {
        return this.get(url, params, headers, true)
    },
    post(url, params = {}, headers = {}) {
        return requestFunc('post', url, params, headers)
    },
    put(url, params = {}, headers = {}) {
        return requestFunc('put', url, params, headers)
    },
    patch(url, params = {}, headers = {}) {
        return requestFunc('patch', url, params, headers)
    },
    delete(url, params = {}, headers = {}) {
        return requestFunc('delete', url, params, headers)
    },
    host,
}