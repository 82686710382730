<!-- 药品对比弹窗 -->
<template>
    <div class="custom-dialog" ref="dialogWrapper"  v-drag-div>
        <div class="dialog-header dragging">
            <!-- 弹框标题和关闭按钮等内容 -->
            药品对比（已选{{$store.state.contrastList.length > 0 ? $store.state.contrastList.length : 0}}）
            <i class="el-icon-close dragging" style="cursor: pointer !important;font-size: 18px;"
                @click.stop="closePay"></i>
        </div>
        <div class="dialog-content">
            <!-- 弹框主体内容 -->
            <template v-if="$store.state.contrastList && $store.state.contrastList.length !== 0">
                <div class="comText_height">
                    <div class="comText_com" v-for="(orr, i) in $store.state.contrastList" :key="orr.id">
                        {{ orr.comName }}
                        <i class="el-icon-delete" style="color: red;"
                            @click="$store.state.contrastList.splice(i, 1)"></i>
                    </div>
                </div>
                <div class="comText_btn" @click="goContrast">跳转药品对比</div>
            </template>
            <div class="comText_com" v-else style="text-align: center;">
                暂无数据！
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isDragging: false,
            dragStartX: 0,
            dragStartY: 0,
        };
    },
    methods: {
        goContrast() {
            const { href } = this.$router.resolve({
                path: '/drug-compare',
            });
            localStorage.setItem("all_contrastList", JSON.stringify(this.$store.state.contrastList));
            window.open(href, '_blank');
        },
        closePay() {
            this.$emit('close')
            this.isDragging = false;
        },
    },
};
</script>

<style scoped lang="scss">
.custom-dialog {
    position: fixed;
    top: 30%;
    left: 40%;
    z-index: 1000;
    width: 400px;
    max-height: 400px;
    border-radius: 5px;
    // background: #F7F7F7 !important;
    color: #303133 !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
    padding: 0 10px 10px;
    box-sizing: border-box;
    background: radial-gradient(49% 49% at 105% -11%, #cdd8fb 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(57% 57% at -13% -22%, #c4d2f3 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #E3EEFC 0%, #FEFFFF 33%, #FFFFFF 83%);
}

.dialog-header {
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dialog-wrapper {
    position: absolute;
    user-select: none;
}

.dialog-content {
    width: 100%;

    .comText_height {
        max-height: 200px;
        overflow: auto;

        .comText_com {
            // width: 200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            padding: 4px 15px 4px 5px;
        }
    }

    .comText_btn {
        background: #6684EC;
        display: inline-block;
        color: #fff;
        padding: 2px 5px;
        border-radius: 3px;
        margin-top: 5px;
        float: right;
        cursor: pointer;
    }
}

.dragging {
    cursor: move;
}
</style>