<style lang="scss">
    .my-dialog {
        .el-dialog {
            margin-bottom: 0 !important;
            &__header {
                background: url('~@/assets/img/dialog-head.png') no-repeat center / 110% 110%;
            }
            &__title {
                color: #fff;
            }
            &__close {
                color: #fff;
            }
            &__body {
                padding: 10px 20px;
            }
            &__footer {
                padding: 12px 20px;
                background: #f6f6f6;
            }
        }
        .this-btn {
            padding: 7px 20px;
            line-height: 18px;
            font-weight: normal;
        }
        &__msg {
            padding: 15px 0;
        }
    }
</style>

<template>
    <el-dialog class="my-dialog" v-dialogDrag :visible.sync="show" :title="title" :with-header="true" :modal="!hideModal"
               :close-on-click-modal="closeOnClickModal" :before-close="close" :show-close="!hideClose" :top="top"
               :close-on-press-escape="false" :append-to-body="true" :width="width">
        <template #title>
            <slot name="title">
                <span class="el-dialog__title">{{title}}</span>
            </slot>
        </template>
        <slot><div v-html="content"></div></slot>
        <span slot="footer" v-if="!hideFooter">
            <slot name="footer">
                <slot name="footLeft"></slot>
                <el-button class="this-btn" type="primary" @click="$emit('save')" :icon="sureIcon" :loading="loading">{{sureTxt}}</el-button>
                <el-button class="this-btn" v-show="!hideCancel" style="margin-left: 25px;"
                           @click="cancel" :icon="cancelIcon">{{cancelTxt}}</el-button>
            </slot>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            // 是否隐藏底部按钮
            hideFooter: {
                type: Boolean,
                default: false
            },
            hideCancel: {
                type: Boolean,
                default: false
            },
            hideModal: {
                type: Boolean,
                default: false
            },
            hideClose: {
                type: Boolean,
                default: false
            },
            // 确定按钮文案
            sureTxt: {
                type: String,
                default: '确定'
            },
            sureIcon: {
                type: String,
                default: 'el-icon-check'
            },
            // 取消按钮文案
            cancelTxt: {
                type: String,
                default: '取消'
            },
            cancelIcon: {
                type: String,
                default: 'el-icon-close'
            },
            //内部文本域
            content: {},
            // 如传true，会emit('cancel')
            isCancelEvent: {
                type: Boolean,
                default: false
            },
            // 按钮是否在加载中
            loading: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            width: {
                type: String,
                default: '50%'
            },
            show: {
                type: Boolean,
                default: false
            },
            closeOnClickModal: {
                type: Boolean,
                default: false
            },
            top: {
                type: String,
                default: '15vh'
            },
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            cancel() {
                if (this.isCancelEvent) {
                    this.$emit('cancel')
                } else {
                    this.close()
                }
            },
        }
    }
</script>
