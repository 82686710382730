import help from './help';


function faceToStr(str) {
    const patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
    str = str.replace(patt, (char) => {
        let H;
        let L;
        let code;
        let s;

        if (char.length === 2) {
            H = char.charCodeAt(0); // 取出高位
            L = char.charCodeAt(1); // 取出低位
            code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法
            s = `&#${code};`;
        } else {
            s = char;
        }

        return s;
    });

    return str;
}
const face = faceToStr('😁😂😃😄👿😉😊😌😍😏😒😓😔😖😘😚😜😝😞😠😡😢😣😥😨😪😭😰😱😲😳😷🙃😋😗😛🤑🤓😎🤗🙄🤔😩😤🤐🤒😴😀😆😅😇🙂😙😟😕🙁😫😶😐😑😯😦😧😮😵😬🤕😈👻🥺🥴🤣🥰🤩🤤🤫🤪🧐🤬🤧🤭🤠🤯🤥🥳🤨🤢🤡🤮🥵🥶💩💀👽👾👺👹🤖')

export default {
    SUCCESS_CODE: '00000',
    successCode: '00000',
    ERROR_MSG: '请先选择有效记录，再执行该操作。',
    tel: '400-8676768',
    qq: '2376201058',

    basePageSize: 20,

    uploadSplitStr: '*##*',

    faceList: face
        .split(';')
        .filter(item => item)
        .map(item => {
            item = item + ';'
            return item
        }),

    searchType: {
        'LIKE': 0,
        'SELECT': 1,
        'NUM': 2,
        'DATE': 3,
        'EQ':4,
    },

    splitStr: '*##*',
    minSplitStr: '#**#',
    suffix: 'king',

    colType: {
        normal: 1,
        fuzzy: 2,
        date: 3,
        file: 4,
    },

    tokenInvalid: ['A0001', 'A0002','A0003', 'A0005'],

    // 会员类型
    member: {
        temp: '0330402af5fd46d192c18ac1629530d0',
        visit: 'd726082240ac4c29acefcc10e1202b8b',
        vip: 'bd0cde66e4964a9db954c5ea453566cb',
    },
    unit: '万元',

    // 首页三种类型
    indexType: {
        DRUG: 1,
        CORP: 2,
        ASSIST: 3,
    },
    years: [],
    yearMap: {},
    quarterMap: {},
    lastYear: 1991,
    yearsLength: 0,
    lastQuarter: {},
    getYearCols(obj = {}) {
        return this.years.map(item => {
            return help.joinObj({
                fieldName: 'amount' + item.keyYear,
                name: item.value + '年',
                listOrderView: '是',
                width: 60,
                listClass: 'tc'
            }, obj)
        })
    },
    getYearRateCols(obj = {}) {
        return this.years.map(item => {
            return help.joinObj({
                fieldName: 'rate' + item.key,
                name: item.value + '年增长比%',
                listOrderView: '是',
                listClass: 'tc',
                width: 60,
            }, obj)
        })
    },
    quarter: [],
    getQuarterCols(obj = {}) {
        return this.quarter.map(item => {
            return help.joinObj({
                fieldName: 'amount' + item.key,
                name: item.value,
                listOrderView: '是',
                width: 90,
                listClass: 'tc'
            }, obj)
        })
    },
    getQuarterRateCols(obj = {}) {
        const arr = []
        this.quarter.forEach(quarter => {
            arr.push(help.joinObj({
                fieldName: 'rate' + quarter.key,
                name: quarter.value + '增长比%',
                listOrderView: '是',
                width: 80,
                listClass: 'tc'
            }, obj))
        })
        return arr
    },
    indexColumn: {
        drug(param) {
            return help.joinObj({
                fieldName: 'comName',
                name: '药品通用名',
                listOrderView: '是',
                width: 120,
                listQueryView: '是',
                listQueryModel: 1,
                listView: '是',
            }, param)
        },
        corp(param) {
            return help.joinObj({
                fieldName: 'compName',
                name: '企业',
                listOrderView: '是',
                width: 120,
                listQueryView: '是',
                listQueryModel: 1,
                listView: '是',
            }, param)
        }
    },
}