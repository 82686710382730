import api from '@/util/api'

function getToken() {
    return api.get('sys/qiniu/token')
}
const uploadBase64 = '//upload-z2.qiniup.com/putb64/-1'

export default {
    getToken,
    upload: '//up-z2.qiniup.com',
    uploadBase64,

    uploadByBase64(data) {
        return new Promise((resolve, reject) => {
            api.get('sys/qiniu/token')
                .then(res => {
                    if(res.success){
                        const pic = data.substr(data.indexOf('base64,') + 7)
                        const url = uploadBase64
                        const xhr = new XMLHttpRequest()
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState === 4){
                                resolve(JSON.parse(xhr.responseText).data)
                            }
                        }
                        xhr.open("POST", url, true)
                        xhr.setRequestHeader("Content-Type", "application/octet-stream")
                        xhr.setRequestHeader("Authorization", 'UpToken ' + res.data)
                        xhr.send(pic)
                    }
                })
                .catch(reject)
        })
    }
}