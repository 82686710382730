<template>
    <div>
        <div class="drug-develop">
            <div class=" drug-header">
                <div class="drug-base">
                    <div class="base_top">
                        <div class="iconfont icon-a-ziyuan24 oddImage" :class="imgColor">
                            <span class="oddImage_text">{{ imgText }}</span>
                        </div>
                        <div class="drug-base__title border">
                            <div class="bg-green">
                                <el-image :src="imgIcon_2" fit="fill"></el-image>
                                <span class="text">基本信息</span>
                            </div>
                        </div>
                        <div class="drug-base__list">
                            <div class="item flex_2">
                                <div class="label">受理号：</div>
                                <div class="value">{{ baseInfo.referenceNumber }}</div>
                            </div>
                            <div class="item flex_2">
                                <div class="label">药品类型：</div>
                                <div class="value">{{ baseInfo.drugTypeName }}</div>
                            </div>
                            <div class="item flex_2">
                                <div class="label">注册分类：</div>
                                <div class="value">{{ baseInfo.registeredClassification }}</div>
                            </div>
                            <div class="item flex_2">
                                <div class="label">申请类型：</div>
                                <div class="value">{{ baseInfo.applicationTypeName }}</div>
                            </div>
                            <div class="item flex_1">
                                <div class="label">药品名称：</div>
                                <div class="value">{{ baseInfo.comName }}</div>

                            </div>
                            <div class="item flex_1">
                                <div class="label">企业：</div>
                                <div class="value1">{{ baseInfo.compNames }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="base_bottom">
                        <b>点击跳转到想要的信息部分</b>
                        <div>
                            <button v-for="item in numberList" :class="item.add" class="base_bottom_but" size="mini"
                                :key="item.num" :style="item.color" @click="clickButtom(item.add)">{{ item.num
                                }}</button>
                        </div>
                    </div> -->
                </div>
                <div class="drug-base">
                    <div class="drug-base__title border">
                        <div class="bg-green">
                            <el-image :src="imgIcon_1" fit="fill"></el-image>
                            <span class="text">业务分类</span>
                        </div>
                    </div>
                    <div class="drug-base__list">
                        <div class="item flex_3">
                            <div class="label">优先审评</div>
                            <div class="value">：{{ classificationProfessional.prio | isBool }}</div>
                        </div>
                        <div class="item flex_re_3">
                            <template>
                                <el-popover v-if="classificationProfessional.prio" title="优先审评依据：" trigger="hover"
                                    width="300" placement="top"
                                    :content="classificationProfessional.prio.evaluationBasis">
                                    <div class="value" slot="reference">优先审评依据：
                                        {{ classificationProfessional.prio.evaluationBasis }}
                                    </div>
                                </el-popover>
                                <div class="value" slot="reference" v-else>优先审评依据：
                                    --
                                </div>
                            </template>
                        </div>
                        <div class="item flex_3">
                            <div class="label">突破性治疗药物</div>
                            <div class="value">：{{ classificationProfessional.break | isBool }}</div>
                        </div>
                        <div class="item flex_re_3">
                            <template>
                                <el-popover v-if="classificationProfessional.break" title="突破性治疗药物依据：" trigger="hover"
                                    width="300" placement="top"
                                    :content="classificationProfessional.break.evaluationBasis">
                                    <div class="value" slot="reference">突破性治疗药物依据：
                                        {{ classificationProfessional.break.evaluationBasis }}
                                    </div>
                                </el-popover>
                                <div class="value" slot="reference" v-else>突破性治疗药物依据：
                                    --
                                </div>
                            </template>
                        </div>
                        <div class="item flex_3">
                            <div class="label">特殊审评</div>
                            <div class="value">：{{ classificationProfessional.special | isBool }}</div>
                        </div>
                        <div class="flex_1 full">
                            <div class="label">寄发信息：</div>
                            <div class="value">{{ classificationProfessional.expressage ?
                            classificationProfessional.expressage : '否' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="drug_foot" :style="drugFootStyle">
                <div class="drug_foot_process">
                    <div class=" flex_re_5">
                        <el-image :src="imgIcon_14" fit="fill"></el-image>
                        <span class="text">审评审批流程</span>
                    </div>
                    <div class="flex_4 radio">
                        <el-radio-group v-model="radio" size="mini">
                            <el-radio-button label="1">
                                <el-image style="left:6px;top: 5px;" :src="imgIcon_13" fit="fill"></el-image>
                                <span style="margin-left: 8px;">简易流程图</span>
                            </el-radio-button>
                            <el-radio-button label="2">
                                <el-image style="top: 5px;" :src="imgIcon_12" fit="fill"></el-image>
                                <span style="margin-left: 20px;">流程图</span>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="drug_foot_bottom">
                    <div v-show="radio === '2'">
                        <!-- 综合评审审批进度 -->
                        <div class="check_process" id="a1">
                            <div class="check_process_left">
                                <span class="check_process_text">综合评审审批进度</span>
                            </div>
                            <div class="check_process_right">
                                <div class="process_right_top">
                                    <div class="left "></div>
                                    <div class="right ">办理状态: {{ reviewProgress.reviewStatusName }}</div>
                                </div>
                                <div class="process_right_bottom">
                                    <div class="bottom_item">
                                        <div class="flex_6">
                                            <div>
                                                预计审评时间:
                                                <span class="value color-red ">{{ reviewProgress.maxEstimatedReviewTime
                            | dayTime
                                                    }}</span>
                                            </div>
                                        </div>
                                        <div class="flex_6">
                                            <div>
                                                预计审评完毕日期：
                                                <span class="value color-red ">{{ reviewProgress.estimatedAccomplishTime
                                                    }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom_item">
                                        <div class="flex_6">
                                            <div>
                                                承办时间：
                                                <span class="value">{{ reviewProgress.undertakeDate }}</span>
                                            </div>
                                        </div>
                                        <div class="flex_6">
                                            <div>
                                                状态开始时间：
                                                <span class="value ">{{ reviewProgress.beginTime }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom_item">
                                        <div class="flex_6">
                                            <div>
                                                审评结论：
                                                <span class="value">{{ reviewProgress.cdeConclusionName }}</span>
                                            </div>
                                        </div>
                                        <div class="flex_6">
                                            <div>
                                                批准文号：
                                                <span class="value ">{{ reviewProgress.approvalNumber }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 审评任务：新报任务  / 补充资料 / 新报一致性评价 / 直接行政审批-->
                        <div v-for="item in taskList" :key="item.mainKey" :id="item.id">
                            <div class="checkTow_process" v-if="JSON.stringify(taskMap[item.mainKey]) !== '{}'">
                                <div class="checkTow_process_left" :class="item.bgColor">
                                    <span class="check_process_text">审评任务: {{ item.title }}</span>
                                </div>
                                <div class="checkTow_process_right">
                                    <div class="checkTow_right_top">
                                        <div class="leftList ">
                                            <el-image :src="item.icon1" fit="fill"></el-image>
                                            {{ item.title }}
                                        </div>
                                        <div class="right ">审评状态: {{ taskMap[item.mainKey].reviewStatusName }}</div>
                                    </div>
                                    <div class="checkTow_right_bottom">
                                        <div class="bottom_main" v-if="JSON.stringify(taskMap[item.mainKey]) !== '{}'">
                                            <div class="flex_6" v-for="col in item.cols" :key="col.key">
                                                <div>
                                                    <span class="label" :class="[col.color ? col.color : '']">{{
                            col.value
                        }}:</span>
                                                    <span class="value " :class="[col.color ? col.color : '']">
                                                        {{ col.suffix ? taskMap[item.mainKey][col.key]
                            ? taskMap[item.mainKey][col.key] + col.suffix : ''
                            : taskMap[item.mainKey][col.key] }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bottom_middle" v-if="!item.hiddenCon">
                                            <div class="leftList ">
                                                <el-image style="top:7px;" :src="item.icon2" fit="fill"></el-image>
                                                审评内容
                                            </div>
                                        </div>
                                        <div class="bottom_foot" v-if="!item.hiddenCon">
                                            <div class="bottom_foot_main" v-for="con in reConList"
                                                :key="'new' + con.value">
                                                <div class="main_1 main_size">{{ con.value }}</div>
                                                <div class="item flex_4 bg_main">
                                                    <span class="">状态</span>
                                                    <span class="value">：{{ taskMap[item.mainKey][con.key + 'Name'] |
                            changeState }}</span>
                                                </div>
                                                <div class="main_1">
                                                    <span style="margin-right: 20px;">开始时间：{{
                            taskMap[item.mainKey][con.key +
                            'StartTime'] }}</span>
                                                    <span>结束时间：{{ taskMap[item.mainKey][con.key + 'EndTime'] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bottom_under">
                                            <div class="bottom_under_top">
                                                <div class=" bg_top">其他</div>
                                            </div>
                                            <div class="main_1 bottom_under_foot">
                                                <span>备注：</span>
                                                <span>{{ taskMap[item.mainKey].remark }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 三合一序 / 药品注册生产现场检查进度 / 审评任务调整-->
                        <div v-for="item in mixList" :key="item.mainKey" :id="item.id">
                            <div class="checkTow_process" v-if="JSON.stringify(mixMap[item.mainKey]) !== '{}'">
                                <div class="checkTow_process_left" :class="item.bgColor">
                                    <span class="check_process_text">{{ item.title }}</span>
                                </div>
                                <div class="checkTow_process_right">
                                    <div class="checkTow_right_top">
                                        <div class="leftList ">
                                            <el-image :src="item.icon1" fit="fill"></el-image>
                                            {{ item.title }}
                                        </div>
                                        <div class="right ">
                                            {{ item.statusName }}{{ mixMap[item.mainKey][item.statusField] }}</div>
                                    </div>
                                    <div class="checkTow_right_bottom">
                                        <div class="bottom_main" v-if="JSON.stringify(mixMap[item.mainKey]) !== '{}'">
                                            <div class="flex_6" v-for="col in item.cols" :key="col.key">
                                                <div>
                                                    <span class="label" :class="[col.color ? col.color : '']">{{
                            col.value
                        }}:</span>
                                                    <span class="value " :class="[col.color ? col.color : '']">
                                                        {{ col.suffix ? mixMap[item.mainKey][col.key]
                            ? mixMap[item.mainKey][col.key] + col.suffix : ''
                            : mixMap[item.mainKey][col.key] }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bottom_under">
                                            <div class="bottom_under_top">
                                                <div class=" bg_top">{{ item.mixText }}</div>
                                            </div>
                                            <div class="main_1 bottom_under_foot">
                                                <span>备注：</span>
                                                <span>{{ mixMap[item.mainKey].remark }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="radio === '1'">
                        <div class="oddMain" style="padding: 0px;">
                            <el-button class="oddMain_button" type="primary" plain icon="el-icon-upload2"
                                @click="leadingOutImg">导出</el-button>
                            <div id="oddMain" style="padding: 20px;">
                                <div class="oddMain_main">
                                    <div class="btn oddMain_main__btn" style="background: #45d75f;">开始</div>
                                    <div class="oddMain_main__box">
                                        <div class="box_flex box_All">
                                            <div class="box_flex_left box_flex_bigCircle">{{
                            reviewProgress.undertakeDate }}
                                            </div>
                                            <div class="box_flex_right box_flex_title">NMPA承办</div>
                                        </div>
                                        <div v-for="(row, key) in newFlowChart" :key="row.newType + key">
                                            <div
                                                v-if="row.newType === 'taskMap' ? JSON.stringify(taskMap[row.mainKey]) !== '{}' : JSON.stringify(mixMap[row.mainKey]) !== '{}'">
                                                <div class="box_flex box_All">
                                                    <div class="box_flex_left box_flex_bigCircle">
                                                        预审时间：
                                                        <span style="color: #ED1212;" v-if="row.newType === 'taskMap'">
                                                            {{ taskMap[row.mainKey][row.mainTime] | dayTime }}
                                                        </span>
                                                        <span style="color: #ED1212;" v-else>
                                                            {{ mixMap[row.mainKey][row.mainTime] | dayTime }}
                                                        </span>
                                                    </div>
                                                    <div class="box_flex_right">
                                                        <div class="box_flex_btn btn">
                                                            {{ row.title }}
                                                            <!-- <span v-if="row.mainValue">
                                                            -{{ row.mainValue }}：{{ row.newType === 'taskMap' ?taskMap[row.mainKey][row.mainType] :mixMap[row.mainKey][row.mainType] }}
                                                        </span> -->
                                                            <span>
                                                                ：{{ row.newType === 'taskMap'
                            ? taskMap[row.mainKey][row.mainType]
                            : mixMap[row.mainKey][row.mainType] }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="box_flex box_All" v-for="(rowTwo, index) in row.list"
                                                    :key="index">
                                                    <div class="box_flex_left box_flex_smallCircle ">
                                                        {{ rowTwo.time ? row.newType === 'taskMap' ?
                            taskMap[row.mainKey][rowTwo.time]
                            : mixMap[row.mainKey][rowTwo.time] : '' }}
                                                    </div>
                                                    <div class="box_flex_right box_flex_line">
                                                        <div v-if="rowTwo.value === '其他'" class="btn"
                                                            :style="rowTwo.btnStyle">
                                                            {{ rowTwo.value }}</div>
                                                        <template v-else-if="rowTwo.btn">
                                                            <div style="display: flex;align-items: center;">
                                                                {{ rowTwo.value }}
                                                                <div class="btn" :style="rowTwo.btnStyle"
                                                                    v-if="row.newType === 'taskMap' ? taskMap[row.mainKey][rowTwo.key] : mixMap[row.mainKey][rowTwo.key]">
                                                                    {{ row.newType === 'taskMap'
                            ? taskMap[row.mainKey][rowTwo.key]
                            : mixMap[row.mainKey][rowTwo.key] }}
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <template
                                                            v-else-if="rowTwo.children && rowTwo.children.length > 0">
                                                            <div class="box_flex_title">{{ rowTwo.value }}</div>
                                                            <div
                                                                style="display: flex;flex-wrap: wrap;justify-content: space-evenly;width: 100%;">
                                                                <div class="title_main"
                                                                    v-for="(rowThree, i) in rowTwo.children"
                                                                    :key="rowThree.value + i">
                                                                    <p style="font-size: 12px;font-weight: bold;">
                                                                        {{ rowThree.value }}</p>
                                                                    <span>开始时间：
                                                                        {{ taskMap[row.mainKey][rowThree.key +
                            'StartTime'] }}
                                                                    </span>
                                                                    <span>结束时间：{{ taskMap[row.mainKey][rowThree.key +
                            'EndTime']
                                                                        }}</span>
                                                                    <div class="title_main_state">
                                                                        状态：{{ taskMap[row.mainKey][rowThree.key +
                            'Name'] |
                            changeState }}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            {{ rowTwo.value }}{{ row.newType === 'taskMap' ?
                                                            taskMap[row.mainKey][rowTwo.key] :
                                                            mixMap[row.mainKey][rowTwo.key]}}
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 综合进度 -->
                                        <div class="box_flex box_All">
                                            <div class="box_flex_left box_flex_bigCircle ">预审时间：
                                                <span style="color: #ED1212;">{{
                                                    reviewProgress.maxEstimatedReviewTime
                                                    | dayTime
                                                    }}</span>
                                            </div>
                                            <div class="box_flex_right">
                                                <div class="box_flex_btn btn"> 综合评审审批进度</div>
                                            </div>
                                        </div>
                                        <div class="box_flex box_All">
                                            <div class="box_flex_left box_flex_smallCircle ">{{ reviewProgress.beginTime
                                                }}
                                            </div>
                                            <div class="box_flex_right box_flex_line">
                                                <span>办理状态：{{ reviewProgress.reviewStatusName }}</span>
                                            </div>
                                        </div>
                                        <div class="box_flex box_All">
                                            <div class="box_flex_left box_flex_smallCircle "></div>
                                            <div class="box_flex_right box_flex_line">
                                                <span>审评结论：{{reviewProgress.cdeConclusionName }}</span>
                                                <span>批准文号：{{ reviewProgress.approvalNumber }}</span>
                                            </div>
                                        </div>
                                        <div class="box_flex box_All" v-if="taoshuForecast">
                                            <div class="box_flex_left box_flex_smallCircle "></div>
                                            <div class="box_flex_right box_flex_line">
                                                <span>淘数预测：{{imgText }}</span>
                                            </div>
                                        </div>
                                        <!--endregion-->
                                    </div>
                                    <div class="btn oddMain_main__btn" style="background: #45d75f;">结束</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const newReConList = [
    { value: '药理毒理', key: 'pharmaToxi' },
    { value: '临床', key: 'clinic' },
    { value: '药学', key: 'pharmacy' },
    { value: '统计', key: 'stats' },
    { value: '临床药理', key: 'clinicalPharma' },
    { value: '合规', key: 'compliance' },
]
const childrenList = [   //新报任务、补充资料任务
    { time: 'reviewEntryTime', value: '进入审评序列' },
    { key: 'rank', value: '排序：', },
    { value: '审评内容', children: newReConList },
    { time: 'reviewOutTime', value: '离开审评序列' },
    { key: 'reviewStatusName', value: '审评状态：', },
    { value: '其他', btnStyle: { background: '#EBEBEB', color: '#A1A1A1', }, },
    { key: 'remark', value: '备注：' },
]
import html2canvas from 'html2canvas'
export default {
    name: '',
    props: {
        approvalData: Object,
    },
    data() {
        return {
            newDrugFootHeight: '',

            imgIcon_1: require('@/assets/img/app-flow/icon_1.png'),
            imgIcon_2: require('@/assets/img/app-flow/icon_2.png'),
            imgIcon_6: require('@/assets/img/app-flow/icon_6.png'),
            imgIcon_12: require('@/assets/img/app-flow/icon_12.png'),
            imgIcon_13: require('@/assets/img/app-flow/icon_13.png'),
            imgIcon_14: require('@/assets/img/app-flow/icon_14.png'),
            imgIcon_15: require('@/assets/img/app-flow/icon_15.png'),
            imgColor: '',
            imgText: '',

            numberList: [
                { num: 1, color: 'backgroundColor:#17CE9D', add: 'a1' },
                { num: 2, color: 'backgroundColor:#2295F5', add: 'a2' },
                { num: 3, color: 'backgroundColor:#F2B449', add: 'a3' },
                // {num:4,color:'backgroundColor:#59B0FE',add:'a4'},
                { num: 4, color: 'backgroundColor:#ED7C45', add: 'a5' },
                { num: 5, color: 'backgroundColor:#FC8E87', add: 'a6' },
                // {num:7,color:'backgroundColor:#599EE9',add:'a7'},
                { num: 6, color: 'backgroundColor:#4F76FC', add: 'a8' },
            ],
            radio: '1',
            baseInfo: {}, // 基本信息
            reviewProgress: {}, //综合评审 
            classificationProfessional: {}, //业务分类
            taskMap: {
                newReportTask: {}, //新报任务
                suppMaterialTask: {}, //补充资料任务
                //   consistencyNewReports: {}, //新报一致性评
                govReviewApproval: {}, //直接行政审批
            },
            taskList: [{
                id: 'a2',
                mainKey: 'newReportTask',
                title: '新报任务',
                icon1: require('@/assets/img/app-flow/icon_11.png'),
                icon2: require('@/assets/img/app-flow/icon_3.png'),
                bgColor: 'bg-green1',
                cols: this.getCols('newReportTask')
            },
            {
                id: 'a3',
                mainKey: 'suppMaterialTask',
                title: '补充资料',
                icon1: require('@/assets/img/app-flow/icon_9.png'),
                icon2: require('@/assets/img/app-flow/icon_3.png'),
                bgColor: 'bg-purple',
                cols: this.getCols('suppMaterialTask')
            },
            {
                id: 'a5',
                mainKey: 'govReviewApproval',
                title: '直接行政审批',
                icon1: require('@/assets/img/app-flow/icon_5.png'),
                bgColor: 'bg-yellow',
                cols: this.getAllCols('govReviewApproval'),
                hiddenCon: true
            }
            ],
            mixMap: {
                trinityApproval: {}, //三合一序
                //   nationalInspectionSchedule: {}, //药品注册生产现场检查进度
                reviewAdjustment: {}, //审评任务调整
            },
            mixList: [
                {
                    id: 'a6',
                    mainKey: 'trinityApproval',
                    title: '三合一序',
                    icon1: require('@/assets/img/app-flow/icon_5.png'),
                    statusName: '当前状态:',
                    statusField: 'reviewStatusName',
                    bgColor: 'bg-red',
                    mixText: '其他',
                    cols: [{
                        key: 'estimatedReviewTime', value: '预计审评时间',
                        color: 'color-red', bold: true, suffix: '日', flex: 'flex_2'
                    },
                    {
                        key: 'estimatedAccomplishTime', value: '预计审评完毕日期',
                        color: 'color-red', bold: true, flex: 'flex_2'
                    },
                    { key: 'censorate', value: '现场检查机构', flex: 'flex_2' },
                    { key: 'informInspectDate', value: '通知现场检查时间', flex: 'flex_2' },
                    { key: 'compApplyDate', value: '企业申请现场检查时间', flex: 'flex_2' },
                    { key: 'receiptTimeDate', value: '检查/检验报告接收时间', flex: 'flex_2' },
                    { key: 'inspectDate', value: '现场检查时间', flex: 'flex_2' }],
                },
                {
                    id: 'a8',
                    mainKey: 'reviewAdjustment',
                    title: '审评任务调整',
                    icon1: require('@/assets/img/app-flow/icon_4.png'),
                    // statusName: '现场检查进度:',
                    // statusField: 'reviewStatusName',
                    isHideStatus: true,
                    bgColor: 'bg_oran',
                    mixText: '其他',
                    cols: [{ key: 'adjustmentTime', value: '调整时间', flex: 'flex_1' },
                    { key: 'adjustmentCause', value: '调整原因', flex: 'flex_1' }],
                }
            ],

            reConList: newReConList, //审评内容



            newFlowChart: [
                {
                    newType: 'taskMap',
                    mainKey: 'newReportTask',
                    title: '新报任务',
                    mainTime: 'maxEstimatedReviewTime',    //预计审评时间
                    mainType: 'taskTypeName',    //任务分类
                    // mainValue: '任务分类',
                    list: childrenList,
                },
                {
                    newType: 'taskMap',
                    mainKey: 'suppMaterialTask',
                    title: '补充资料',
                    mainTime: 'maxEstimatedReviewTime',    //预计审评时间
                    mainType: 'inspDepartName',    //审评部门
                    // mainValue: '审评部门',
                    list: childrenList,
                },
                {
                    newType: 'taskMap',
                    mainKey: 'govReviewApproval',
                    title: '直接行政审批',
                    mainTime: 'estimatedReviewTime',    //预计审评时间
                    mainType: 'publicityBigTypeName',    //任务分类
                    // mainValue: '任务分类',
                    list: [
                        { time: 'startTime', value: '进入审评序列' },
                        { key: 'rank', value: '排序：', },
                        // { value: '审评内容', children: newReConList },
                        { time: 'endTime', value: '离开审评序列' },
                        { key: 'reviewStatusName', value: '审评状态：', btn: true, btnStyle: { background: '#F7AE00', color: '#FFFFFF', }, },
                        { value: '其他', btnStyle: { background: '#EBEBEB', color: '#A1A1A1', }, },
                        { key: 'remark', value: '备注：' },
                    ],
                },
                {
                    newType: 'mixMap',
                    mainKey: 'trinityApproval',
                    title: '三合一序',
                    mainTime: 'estimatedReviewTime',    //预计审评时间
                    list: [
                        { time: 'compApplyDate', value: '企业申请现场检查时间' },
                        { time: 'informInspectDate', value: '通知现场检查时间' },
                        { time: 'receiptTimeDate', value: '检查/检验报告接收时间' },
                        { key: 'censorate', value: '现场检查机构：' },
                        { time: 'inspectDate', value: '现场检查时间' },
                        { key: 'reviewStatusName', value: '当前状态：', btn: true, btnStyle: { background: '#F7AE00', color: '#FFFFFF', }, },
                        { value: '其他', btnStyle: { background: '#EBEBEB', color: '#A1A1A1', }, },
                        { key: 'remark', value: '备注：' },
                    ],

                },
                {
                    newType: 'mixMap',
                    mainKey: 'reviewAdjustment',
                    title: '审评任务调整',
                    list: [
                        { time: 'adjustmentTime', value: '调整时间' },
                        { key: 'adjustmentCause', value: '调整原因：' },
                        { value: '其他', btnStyle: { background: '#EBEBEB', color: '#A1A1A1', }, },
                        { key: 'remark', value: '备注：' },
                    ],
                },
            ],

            wmConfig: {
                font: "microsoft yahei", //字体
                textArray: ["淘数网"], //水印文本内容，允许数组最大长度3 即：3行水印
                density: 5, //密度 建议取值范围1-5   值越大，水印越多，可能会导致水印重叠等问题，请慎重！！！
            },
        };
    },
    computed: {
        drugFootStyle() {
            return {
                height: this.newDrugFootHeight ? this.newDrugFootHeight : ''
            };
        },
        taoshuForecast() {
            let falg = true
            if (this.reviewProgress.maxEstimatedReviewTime && this.reviewProgress.beginTime && this.reviewProgress.undertakeDate) {
                const oneDay = 24 * 60 * 60 * 1000; // 每天的毫秒数
                const firstDate = new Date(this.reviewProgress.beginTime);
                const secondDate = new Date(this.reviewProgress.undertakeDate);
                // 计算时间差
                const diff = Math.abs(firstDate - secondDate);
                // 返回相差的天数
                let num = Math.round(diff / oneDay)
                falg = this.reviewProgress.maxEstimatedReviewTime < num || false
            } else if (this.reviewProgress.cdeConclusionName) {
                falg = false
            }
            return falg
        },
    },
    methods: {
        leadingOutImg() {
            let oddMain = document.getElementById('oddMain')
            html2canvas(oddMain, {
                width: oddMain.offsetWidth || 200, // 根据需求进行配置截图的尺寸
                height: oddMain.offsetHeight || 200, // 根据需求进行配置截图的尺寸
                allowTaint: false,
                useCORS: true, //图片跨域
                dpi: 300,  //解决生产图片模糊
                backgroundColor: "#fff", // 一定要添加背景颜色，否则出来的图片，背景全部都是透明的
            }
            ).then((canvas) => {
                // 添加水印的 Canvas 操作
                let ctx = canvas.getContext('2d');
                ctx.font = '20px Arial';
                ctx.fillStyle = 'rgba(59,100,240, 0.1)'; // 水印颜色和透明度
                //水印旋转
                ctx.rotate(-20*Math.PI/180);
                for(let i=0;i<oddMain.offsetWidth+100;i += 100){
                    for(let j=0;j<oddMain.offsetHeight+100;j += 100){
                        ctx.fillText('淘数网', oddMain.offsetWidth -i + 100, oddMain.offsetHeight -j + 400); // 水印文字及位置
                    }
                }
                const src = canvas.toDataURL('image/png')
                let dlLink = document.createElement('a');
                dlLink.download = new Date().getTime();
                dlLink.href = src;
                document.body.appendChild(dlLink);
                dlLink.click();
                document.body.removeChild(dlLink);
                // const newWindow = window.open('', '_blank');
                // const img = document.createElement('img');
                // img.src = src;
                // newWindow.document.body.appendChild(img);
            });
        },
        newDrugFoot() {
            this.$nextTick(() => {
                let height = document.getElementsByClassName('drug-header')[0].offsetHeight + 10
                let box_height = document.getElementsByClassName('approval-dialog__content')[0].offsetHeight
                this.newDrugFootHeight = (box_height - height - 20) + 'px'
            })
        },
        imageCrr(val) {
            this.imgColor = ''
            this.imgText = ''
            let orr = ''
            if (JSON.stringify(val) != '{}') {
                if (val.cdeConclusionName) {
                    this.imgText = val.cdeConclusionName
                    if (val.cdeConclusionName === '不批准') {
                        orr = 'oddImage_red'
                    } else if (val.cdeConclusionName.indexOf('批准') !== -1 || val.cdeConclusionName === '通过一致性评价') {
                        orr = 'oddImage_green'
                    } else {
                        orr = 'oddImage_orange'
                    }
                } else {
                    if (['空', '已发批件', '审批完毕－其他', '制证完毕－已发批件', '已发件', '审批完毕－免发纸质批件',
                        '审批完毕', '审批完毕－待制证', '制证完毕－待发批件', '制证完毕－等待交回旧证'].includes(val.reviewStatusName)) {
                        this.imgText = '不批准'
                        orr = 'oddImage_red'
                    }

                    if (['在审批或补资料', '在审评审批中（在药审中心）', '已受理', '在审批', '在审评', '在审评审批中', '待审评', '待审批'].includes(val.reviewStatusName)) {
                        this.imgText = '在审评审批'
                        orr = 'oddImage_orange'
                    }
                }

            }
            return orr
        },

        //按钮点击
        // clickButtom(val) {
        //     document.getElementById(val).scrollIntoView();

        // },
        getCols(type) {
            let cols = [{
                key: 'maxEstimatedReviewTime', value: '预计审评时间',
                color: 'color-red', suffix: '日'
            },
            {
                key: 'estimatedAccomplishTime', value: '预计审评完毕日期',
                color: 'color-red',
            },
            {
                key: 'taskTypeName', value: '任务分类',
                hidden: ['suppMaterialTask', 'consistencyNewReports'].includes(type)
            },
            {
                key: 'inspDepartName', value: '审评部门',
                hidden: ['newReportTask', 'govReviewApproval'].includes(type)
            },
            { key: 'rank', value: '排序' },
            { key: 'reviewEntryTime', value: '开始时间' },
            { key: 'reviewOutTime', value: '结束时间' }];
            cols = cols.filter(item => {
                return !item.hidden
            })
            return cols
        },
        //直接行政审批
        getAllCols(type) {
            let cols = [{
                key: 'estimatedReviewTime', value: '预计审评时间',
                color: 'color-red', suffix: '日'
            },
            {
                key: 'estimatedAccomplishTime', value: '预计审评完毕日期',
                color: 'color-red',
            },
            {
                key: 'publicityBigTypeName', value: '任务分类',
                hidden: ['suppMaterialTask', 'consistencyNewReports'].includes(type)
            },
            { key: 'rank', value: '排序' },
            { key: 'startTime', value: '开始时间' },
            { key: 'endTime', value: '结束时间' }];
            cols = cols.filter(item => {
                return !item.hidden
            })
            return cols
        },
        dateRadius(min, max) {
            let str = ''
            if (min && !max) str = min + '日';
            if (!min && max) str = max + '日';
            if (min && max) str = min + ' ~ ' + max + '日';
            return str
        }

    },
    filters: {
        isBool(val) {
            let str = '否';
            if (val) str = JSON.stringify(val) !== '{}' ? '是' : '否'
            return str
        },
        changeState(val) {
            let odd = val && val.replace("本专业", "")
            setTimeout(() => {
                let bgMain = document.getElementsByClassName('bg_main');
                let titleMainState = document.getElementsByClassName('title_main_state');
                for (let i = 0; i < bgMain.length; i++) {
                    let bgMainValue = bgMain[i].querySelector('.value').innerHTML
                    if (bgMainValue == '：正在审评') {
                        bgMain[i].classList.add('clickGreen')
                        titleMainState[i] && titleMainState[i].classList.add('clickGreen')
                    } else if (bgMainValue == '：已完成审评') {
                        bgMain[i].classList.add('clickYellow')
                        titleMainState[i] && titleMainState[i].classList.add('clickYellow')
                    } else if (bgMainValue == '：排队待评审') {
                        bgMain[i].classList.add('clickBlue')
                        titleMainState[i] && titleMainState[i].classList.add('clickBlue')
                    } else {
                        bgMain[i].classList.add('clickWith')
                        titleMainState[i] && titleMainState[i].classList.add('clickWith')
                    }
                }
            }, 0)

            return odd;
        },
        dayTime(val) {
            let str = ''
            if (val) str = val + '日'
            return str
        },
    },
    mounted() {
        this.baseInfo = this.approvalData.baseInfo || {}
        this.reviewProgress = this.approvalData.reviewProgress || {}
        let imgArr = this.imageCrr(this.reviewProgress)
        this.$nextTick(() => {
            this.imgColor = imgArr
        })


        this.classificationProfessional = this.approvalData.classificationProfessional || {}
        this.approvalData.newReportTask
            && this.$set(this.taskMap, 'newReportTask', this.approvalData.newReportTask)
        this.approvalData.suppMaterialTask
            && this.$set(this.taskMap, 'suppMaterialTask', this.approvalData.suppMaterialTask)
        this.approvalData.consistencyNewReports
            && this.$set(this.taskMap, 'consistencyNewReports', this.approvalData.consistencyNewReports)
        this.approvalData.govReviewApproval
            && this.$set(this.taskMap, 'govReviewApproval', this.approvalData.govReviewApproval)

        this.approvalData.trinityApproval
            && this.$set(this.mixMap, 'trinityApproval', this.approvalData.trinityApproval)
        this.approvalData.nationalInspectionSchedule
            && this.$set(this.mixMap, 'nationalInspectionSchedule', this.approvalData.nationalInspectionSchedule)
        this.approvalData.reviewAdjustment
            && this.$set(this.mixMap, 'reviewAdjustment', this.approvalData.reviewAdjustment)

        this.newDrugFoot()
    },
}
</script>
<style lang='scss'>
.approval-dialog__content {
    height: calc(100vh - 35px);
    padding: 10px;
    box-sizing: border-box;
    /*overflow: auto;*/
}

$drugMain: #1976d2;

$btnYellow: #F2B348;
$btnRed: #ED1212;
$btnBlue: #456DF8;

.drug-develop {
    font-size: 14px;
    /* border-left: 2px solid $drugMain;
      border-right: 2px solid $drugMain;*/

    .bg-green {
        height: 30px;
        line-height: 30px;
        padding-left: 38px;
        color: #fff;
        border-bottom: 1px solid #ffffff;
        position: relative;
        font-size: 18px;
        font-weight: 900;

        .el-image {
            width: 17px;
            position: absolute;
            left: 15px;
            top: 7px;
        }
    }

    .oddImage {
        position: absolute;
        width: 110px;
        right: 5px;
        top: 30px;
        height: 100px;
        font-size: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(359deg);

        &_text {
            font-size: 15px;
            position: absolute;
        }

        &_red {
            color: #FF0000;
        }

        &_green {
            color: #01FE22;
        }

        &_orange {
            color: #FFBB00;
        }
    }

    .bg-green1 {
        background-image: url('../assets/img/app-flow/bg_2.png');
    }

    .bg-blue {
        background-image: url('../assets/img/app-flow/bg_9.png');
    }

    .bg-purple {
        background-image: url('../assets/img/app-flow/bg_3.png');
    }

    .bg-grey {
        background-color: #f5f5f5;
        padding: 5px;
        border-radius: 5px;
    }

    .bg-orange {
        background-color: #f58b32;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
    }

    .bg_oran {
        background-image: url('../assets/img/app-flow/bg_6.png');
    }

    .bg-add {
        background-image: url('../assets/img/app-flow/bg_5.png');
    }

    .bg-red {
        background-image: url('../assets/img/app-flow/bg_4.png');
    }

    .bg-yellow {
        background-image: url('../assets/img/app-flow/bg_10.png');
    }


    .color-red {
        color: #ff3535;
    }

    .color-green {
        color: #4caf50;
    }

    .bold {
        font-weight: bold;
    }

    .text-right {
        text-align: right;
    }

    .flex_1 {
        width: 100%;
        // margin-bottom: 5px;
    }

    .flex_2 {
        width: 50%;
        padding-right: 6px;
        box-sizing: border-box;
        // margin-bottom: 5px;
    }

    .flex_3 {
        width: 35%;
        // margin-bottom: 25px;
    }

    .flex_4 {
        width: 40%;
        display: flex;
        align-items: center;
    }

    .flex_5 {
        width: 20%;
    }

    .flex_re_3 {
        width: 65%;

        .value {
            max-width: 330px;
        }
    }

    .flex_re_5 {
        width: 60%;
        padding-left: 40px;
        color: #fff;
        position: relative;
        font-size: 18px;
        font-weight: 900;

        .el-image {
            width: 22px;
            position: absolute;
            left: 15px;
            top: 6px;
        }
    }


    .full {
        display: flex;

        .label {
            width: 75px;
            text-align: justify;
            text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
            text-align-last: justify;

        }

        .value {
            margin-right: 30px;
        }
    }

    .drug-header {
        display: flex;
        min-height: 140px;
        // height: 160px;
        margin-bottom: 10px;
        // margin-top: 10px;

        .drug-base:nth-child(1) {
            margin-right: 2%;

            .base_top {
                background-image: url('../assets/img/app-flow/bg_7.png');
                height: 100%;
                margin-bottom: 2%;
                border-radius: 5px;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;

            }

            .base_bottom {
                height: 26%;
                border-radius: 5px;
                background-image: url('../assets/img/app-flow/bg_7.png');
                background-size: cover;
                background-repeat: no-repeat;
                box-sizing: border-box;
                padding-top: 5px;

                b {
                    color: #fff;
                    margin-left: 20px;
                }

                &>div {
                    margin-top: 5px;
                    padding-left: 25px;
                    box-sizing: border-box;
                }

                &_but {
                    width: 23px;
                    height: 23px;
                    border-radius: 50%;
                    color: #fff;
                    border: 1px solid #fff;
                    font-weight: bold;
                    line-height: 19px;
                    margin-right: 17px;
                    cursor: pointer;
                }
            }
        }

        .drug-base:nth-child(2) {
            background-image: url('../assets/img/app-flow/bg_8.png');
            border-radius: 5px;
        }

        .drug-base {
            width: 49%;
            background-size: cover;
            background-repeat: no-repeat;

            &__title {
                .process {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .radio {
                        text-align: center;
                    }
                }
            }

            &__list {
                color: #fff;
                padding: 0px 20px;
                display: flex;
                flex-wrap: wrap;
                margin-top: 6px;
                justify-content: space-between;
                box-sizing: border-box;

                .item {
                    display: flex;

                    .label {
                        min-width: 70px;
                        text-align: justify;
                        text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
                        text-align-last: justify;
                    }

                    .value {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }

                    .value1 {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        line-height: 16px;
                        margin-top: 3px;
                    }
                }
            }

            &__custom {
                padding: 10px 20px;
            }

        }
    }

    .check_process_text {
        color: #fff;
        font-size: 17px;
    }

    .drug_foot_bottom {
        height: calc(100% - 45px);
        overflow: auto;
        box-sizing: border-box;
    }

    .drug_foot {
        // height: calc(98vh - 290px);
        overflow: hidden;

        &_process {
            border-radius: 5px;
            display: flex;
            height: 35px;
            line-height: 35px;
            background-color: #426BF7;
            margin-bottom: 10px;

            .el-radio-button__inner {
                background-color: #7897FF;
                border: none;
                margin-right: 30px;
                border-radius: 5px;
                width: 110px;
                text-align: center;
                color: #fff;
                position: relative;

                .el-image {
                    width: 17px;
                    position: absolute;
                    left: 17px;
                }
            }

            .is-active .el-radio-button__inner {
                background-color: #002193;
            }
        }

        /*radio == 1*/
        .check_process {
            width: 100%;
            height: 150px;
            display: flex;
            margin-bottom: 20px;

            .check_process_left {
                width: 250px;
                height: 100%;
                border-radius: 5px;
                margin-right: 18px;
                background-image: url('../assets/img/app-flow/bg_1.png');
                background-size: cover;
                background-repeat: no-repeat;
                text-align: center;
                line-height: 170px;
            }

            .check_process_right {
                width: 690px;
                height: 100%;

                .process_right_top {
                    width: 100%;
                    height: 40px;
                    background-color: #3E67F3;
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    line-height: 40px;
                    display: flex;
                    font-size: 16px;
                    box-sizing: border-box;

                    .left {
                        width: 50%;
                        color: #fff;
                        padding-left: 45px;
                    }

                    .right {
                        text-align: right;
                        width: 50%;
                        color: #fff;
                        padding-right: 20px;
                    }
                }

                .process_right_bottom {
                    width: calc(100% - 2px);
                    height: calc(100% - 40px);
                    border: 1px solid #000000;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    box-sizing: border-box;
                    padding: 14px 26px;

                    .bottom_item {
                        display: flex;

                        .flex_6 {
                            width: 50%;
                            margin-bottom: 7px;
                            font-size: 15px;

                            .label {
                                color: #3D3D3D;
                                min-width: 70px;
                                text-align: justify;
                                text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
                                text-align-last: justify;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }

        /*radio == 2*/
        .process_white {
            color: #fff;
            font-size: 18px;
            font-weight: 500;
        }

        .process_black {
            font-size: 14px;
            font-weight: 500;
            color: #3D3D3D;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .process_bg {
            width: 40px;
            height: 40px;
            background-image: url('../assets/img/app-flow/icon_15.png');
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .process_vertical {
            height: 34px;
            border-right: 2px dashed #FF7F72;
        }

        .process_vertical2 {
            height: 34px;
            border-right: 2px dashed #4065E2;
        }

        .process_vertical3 {
            height: 34px;
            border-right: 2px dashed red;
        }

        .process_round {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }

        .process_color {
            background-color: #FF7F72;
        }

        .process_colorBlue {
            background-color: #4065E2;
        }

        .reversal {
            flex-direction: row-reverse;
        }

        .oddMain {
            width: 100%;
            min-height: 400px;
            border: 1px solid #000;
            border-radius: 5px;
            padding: 30px 80px;
            box-sizing: border-box;
            position: relative;

            .oddMain_button {
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }

        .leftList {
            width: 50%;
            color: #fff;
            padding-left: 45px;

            .el-image {
                width: 18px;
                position: absolute;
                left: 22px;
                top: 10px;
            }
        }

        .bg_main {
            min-width: 100px;
            height: 30px;
            font-size: 14px;
            border-radius: 5px;
            line-height: 30px;
            text-align: center;
            margin-bottom: 10px;
        }

        .clickBlue {
            background-color: #426BF7 !important;
            color: #fff !important;
        }

        .clickGreen {
            background-color: #17CE9D !important;
            color: #fff !important;
        }

        .clickYellow {
            background-color: #F7BA2A !important;
            color: #fff !important;
        }

        .clickWith {
            background-color: #E0E0E0 !important;
            color: #737373 !important;
        }


        .main_size {
            font-size: 16px;
            color: #3D3D3D;
            font-weight: 600;
        }

        .main_1 {
            margin-left: 14px;
        }


        .checkTow_process {
            width: 100%;
            min-height: 170px;
            display: flex;
            margin-bottom: 20px;

            .checkTow_process_left {
                width: 250px;
                border-radius: 5px;
                margin-right: 18px;
                background-size: cover;
                background-repeat: no-repeat;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .checkTow_process_right {
                width: 690px;
                height: 100%;

                .checkTow_right_top {
                    width: 100%;
                    height: 40px;
                    background-color: #3E67F3;
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    line-height: 40px;
                    display: flex;
                    font-size: 16px;
                    box-sizing: border-box;
                    position: relative;

                    .right {
                        text-align: right;
                        width: 50%;
                        color: #fff;
                        padding-right: 20px;
                    }
                }

                .checkTow_right_bottom {
                    width: calc(100% - 2px);
                    height: calc(100% - 40px);
                    border: 1px solid #000000;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;

                    .bottom_main {
                        padding: 10px 26px;
                        box-sizing: border-box;
                        min-height: 60px;

                        .flex_6 {
                            display: inline-block;
                            width: 50%;
                            margin-bottom: 7px;
                            font-size: 15px;

                            .label {
                                color: #3D3D3D;
                                min-width: 60px;
                                text-align: justify;
                                text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
                                margin-right: 5px;
                            }
                        }
                    }

                    .bottom_middle {
                        width: 100%;
                        height: 30px;
                        line-height: 30px;
                        background-color: #3E67F3;
                        display: flex;
                        font-size: 16px;
                        position: relative;

                    }

                    .bottom_foot {
                        width: 100%;
                        min-height: 300px;
                        padding: 10px 12px;
                        box-sizing: border-box;

                        .bottom_foot_main {
                            width: 50%;
                            display: inline-block;
                            margin-bottom: 10px;
                        }
                    }

                    .bottom_under {
                        width: 100%;
                        height: 70px;
                        padding-left: 12px;
                        padding-right: 12px;
                        box-sizing: border-box;

                        .bottom_under_top {
                            width: 100%;
                            height: 40px;
                            border-top: 1px dashed #D8D8D8;
                            border-bottom: 1px dashed #D8D8D8;
                            display: flex;
                            align-items: center;

                            .bg_top {
                                width: 100px;
                                height: 30px;
                                font-size: 14px;
                                color: #737373;
                                border-radius: 5px;
                                line-height: 30px;
                                text-align: center;
                                background: #E0E0E0;
                            }
                        }

                        .bottom_under_foot {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }



        // 直线流程图

        .oddMain_main {
            .btn {
                color: #fff;
                display: inline-block;
                border-radius: 5px;
                padding: 2px 10px;
            }

            &__btn {
                font-size: 18px;
                margin-left: 175px;
                padding: 4px 40px !important;
            }

            &__box {
                position: relative;
                padding: 15px 0;
                box-sizing: border-box;

                .box_All {
                    position: relative;
                    padding: 3px 0;
                    box-sizing: border-box;
                }

                .box_flex {
                    display: flex;
                    align-items: flex-start;
                    // justify-content: space-between;
                    justify-content: space-between;

                    &_bigCircle::before {
                        content: '';
                        position: absolute;
                        left: 223px;
                        width: 10px;
                        height: 10px;
                        background: #FFFFFF;
                        border-radius: 50%;
                        border: 3px solid $btnBlue;
                    }

                    &_smallCircle::before {
                        content: '';
                        position: absolute;
                        left: 226px;
                        width: 6px;
                        height: 6px;
                        background: #FFFFFF;
                        border-radius: 50%;
                        border: 2px solid #456DF8;
                    }

                    &_line {
                        padding-left: 15px;
                        box-sizing: border-box;
                    }

                    &_line::before {
                        content: '';
                        position: absolute;
                        left: -14px;
                        top: 12px;
                        width: 20px;
                        height: 2px;
                        background: #456DF8;
                    }

                    &_left {
                        width: 26%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        min-height: 26px;
                    }

                    &_right {
                        width: 70%;
                        position: relative;
                        min-height: 26px;
                        display: flex;
                        // align-items: center;
                        align-items: flex-start;
                        flex-direction: column;
                        justify-content: center;

                        .title_main {
                            width: 26%;
                            height: 80px;
                            font-size: 10px;
                            border-radius: 5px;
                            background: #FFFFFF;
                            border: 1px solid #EDEDED;
                            padding: 6px 6px 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            margin-bottom: 10px;

                            &_state {
                                width: 108%;
                                margin-left: -6px;
                                border-radius: 0px 0px 5px 5px;
                                text-align: center;
                                background: #F7AE00;
                                color: #FFFFFF;
                            }
                        }
                    }

                    &_title {
                        font-weight: bold;
                        font-size: 16px;
                    }

                    &_btn {
                        background: $btnBlue;
                    }
                }
            }

            &__box::before {
                content: '';
                position: absolute;
                left: 230px;
                top: 0;
                display: inline-block;
                width: 2px;
                height: 100%;
                background: $btnBlue;
            }
        }
    }
}
</style>