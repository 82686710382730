<style lang="scss">
.my-cropper {
    position: relative;
    width: 100%;

    &__panel {
        margin: 15px 30px;
    }

    &__btns {
        margin-top: 10px;
        justify-content: space-between;
    }

    .show-preview {
        border: 1px dashed #aaa;
    }
    .placeholder {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 24px;
        color: #999;
        &:hover {
            color: #19aa8d;
        }
    }
}
</style>
<template>
    <ts-dialog :show.sync="show" title="编辑" width="800px" :loading="loading" @save="imgSave">
        <div class="my-cropper">
            <el-row class="my-cropper__panel">
                <el-col :span="11">
                    <vue-cropper v-if="img.length > 0" ref="cropper" :img="img" outputType="png" :outputSize="1"
                                 :fixedBox="true" @realTime="realTime" :canMoveBox="false" :autoCrop="true" :style="{height: height+'px'}"
                                 :autoCropWidth="finallyWidth" :autoCropHeight="finallyHeight">
                    </vue-cropper>
                    <div v-else class="placeholder" :style="{height: height+'px'}" @click="changeImg"><i class="el-icon-folder-opened"></i>选择图片</div>
                    <div class="my-cropper__btns flex">
                        <input type="file" ref="upload" style="display: none" @change="openFile($event, 1)">
                        <el-button class="btn-solid-green" size="mini" @click="changeImg">更换图片</el-button>
                        <el-button size="mini" @click="changeScale(1)"><i class="el-icon-zoom-in"></i></el-button>
                        <el-button size="mini" @click="changeScale(-1)"><i class="el-icon-zoom-out"></i></el-button>
                        <el-button size="mini" @click="rotateLeft"><i class="el-icon-refresh-left"></i></el-button>
                        <el-button size="mini" @click="rotateRight"><i class="el-icon-refresh-right"></i></el-button>
                    </div>
                </el-col>
                <el-col :offset="2" :span="11" class="preview" :style="{height: height+'px'}">
                    <div class="show-preview" style="overflow: hidden" v-show="img.length > 0"
                         :style="{'width': previews.w + 'px', 'height': previews.h + 'px', 'margin': '5px'}">
                        <div :style="previews.div">
                            <img :src="previews.url" :style="previews.img">
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </ts-dialog>
</template>
<script>
import {VueCropper} from 'vue-cropper'
import qiniu from '@/util/qiniu'
export default {
    components: {VueCropper},
    data() {
        return {
            show: false,
            loading: false,

            previews: {},
            img: '',
            height: 400,
            finallyWidth: 100,
            finallyHeight: 100,
        }
    },
    methods: {
        init(url) {
            console.log(url)
            this.show = true
        },

        changeImg() {
            this.$refs.upload.click()
        },
        openFile(e) {
            let file = e.target.files[0]
            if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
                alert('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种')
                return false
            }
            let reader = new FileReader()
            this.fileName = file.name
            reader.onload = (e) => {
                let data
                if (typeof e.target.result === 'object') {
                    data = window.URL.createObjectURL(new Blob([e.target.result]))
                } else {
                    data = e.target.result
                }
                this.img = data
            }
            reader.readAsArrayBuffer(file)
        },

        realTime(data) {
            // 实时预览函数
            this.previews = data
        },
        changeScale(num) {
            num = num || 1
            this.$refs.cropper.changeScale(num)
        },
        rotateLeft() {
            this.$refs.cropper.rotateLeft()
        },
        rotateRight() {
            this.$refs.cropper.rotateRight()
        },

        imgSave() {
            const el = this.$refs.cropper
            if (el) {
                el.getCropData(data => {
                    this.loading = true
                    qiniu.uploadByBase64(data)
                        .then(res => {
                            this.$api.post('sys/sysUser/changeMeInfo', {
                                picUrl: res.url
                            })
                                .then(res1 => {
                                    if (res1.success) {
                                        this.$store.commit('setUserImg', res.url)
                                        this.$store.commit('success', '修改成功')
                                        this.show = false
                                    }
                                })
                        })
                        .catch(() => {
                            this.loading = false
                        })
                })
            } else {
                this.$store.commit('error', '请选择图片')
            }
        },
    }
}
</script>