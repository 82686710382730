<template>
    <el-select  :value="value" @change="updateValue">
        <el-option v-for="year in $variable.years" :key="'year' + year.key" :value="year.key" :label="year.value + '年'">
        </el-option>
    </el-select>
</template>

<script>
    export default {
        props: {
            value: {},
        },
        methods: {
            updateValue(val) {
                this.$emit('input', val)
                this.$emit('change', val)
            },
        }
    }
</script>